@mixin theme($primary-color, $secondary-color, $quaternary-color, $queratry-color) {
    --primary-color: rgb(var(--primary-color));
    --secondary-color: rgb(var(--secondary-color));
    --tertiary-color: rgb(var(--tertiary-color));
    --quaternary-color: rgb(var(--quaternary-color));

    .btn-primary {
        background-color: rgb(var(--primary-color)) !important;
        border-color: rgb(var(--primary-color)) !important;
    }
}
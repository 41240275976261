@import url(//fonts.googleapis.com/css?family=Roboto);
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~ngx-bar-rating/themes/br-default-theme.css";
@import "./custom-theme.scss";
@import './theme';

@font-face {
  font-family: "MarketDeco";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("assets/fonts/Market_Deco.woff"),
  url("./assets/fonts/Market_Deco.woff") format("woff"),
  url("./assets/fonts/Market_Deco.ttf") format("truetype");
}

:root {
  --primary-color: 90, 138, 190;
  --secondary-color: 253, 221, 0;
  --tertiary-color: 136, 35, 52;
  --quaternary-color: 90, 138, 190;
  @include theme(rgb(var(--primary-color)), rgb(var(--secondary-color)), rgb(var(--tertiary-color)), rgb(var(--quaternary-color)));
}


body, a, button, .mat-tab-group, span, p, h1, h2, h3, h4, h5, h6, .mat-table {
  // font-family: "Montserrat", sans-serif !important;
}

body:has(.session-active), .side-bar-header {
  background-image: linear-gradient(45deg, rgba(var(--tertiary-color), 0.65), rgba(var(--secondary-color), 0.8));
}

img {
  border-radius: 2px;
}

.material-icons {
  font-family: "Material Icons" !important;
}

.marktDeco {
  font-family: "MarketDeco", sans-serif !important;
  text-transform: uppercase !important;
}

.blue, .primary-color {
  color: rgb(var(--primary-color)) !important;
}

.yellow, .secondary-color {
  color: rgb(var(--secondary-color)) !important;
}

.red, .tertiary-color {
  color:rgb( var(--tertiary-color)) !important;
}

.quaternary-color {
  color:rgb( var(--quaternary-color)) !important;
}


.white {
  color: #fff !important;
}

.black {
  color: #000 !important;
}

.grey {
  color: #343434 !important;
}

.green-snackbar {
  background: #66a76c !important;
  /*background: #28a745;*/
}

.red-snackbar {
  background: #dc3545 !important;
}

.yellow-snackbar {
  background: #66a76c !important;
}

.blue-snackbar {
  background: #8da8f2 !important;
}

.white-snackbar {
  background: #ffffff !important;
}

.black-snackbar {
  background: #000000 !important;
}

.grey-snackbar {
  background: grey !important;
}

.primary-snackbar {
  background: rgb(var(--primary-color)) !important;
}

.secondary-snackbar {
  background: rgb(var(--secondary-color)) !important;
}

.tertiary-snackbar {
  background: rgb(var(--tertiary-color)) !important;
}

body {
  min-height: 100vh;
  margin: 0;
}

html {
  height: 100%;
  overflow: auto !important;
}

a,
button,
i,
img,
iframe,
.material-icons,
select {
  cursor: pointer;
}

.mat-tab-label {
  opacity: 1 !important;
}

.mat-tab-label-active {
  color: #000 !important;
  background: rgba(var(--primary-color), 0.2) !important;
}

.mat-tab-label mat-icon {
  vertical-align: top !important;
}

.form-control-number {
  width: 40%;
}

.nav-link {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.label-results-counter {
  margin: 11px !important;
}

table.table-hover tbody tr {
  cursor: pointer;
}

input:required,
input:invalid {
  box-shadow: none;
}

input:not([type="email"]).ng-invalid.validation,
select.ng-invalid.validation,
textarea.ng-invalid.validation,
input[type="email"]:required.ng-invalid.validation,
input[type="email"]:not(required):not(
    :placeholder-shown
  ).ng-invalid.validation {
  border-color: red;
}

.mat-select-panel {
  max-width: 100% !important;
}

.cdk-overlay-pane {
  width: auto !important;
  max-height: 98vh;
  max-width: 98vw !important;
}

ss-multiselect-dropdown.validation.ng-invalid
  div
  button.dropdown-toggle.btn-default.btn.btn-secondary.btn-block {
  border: 1px solid red;
}

a {
  color: rgb(var(--primary-color));
}

a:hover {
  color: rgb(var(--primary-color));
  text-decoration: underline;
}

.marketing-img-height {
  max-height: 280px;
  margin: 0 auto;
  padding: 5px;
}

.modal {
  padding-top: 60px;
}

.displayNone {
  display: none;
}

@media (max-width: 1050px) {
  .floatUnset {
    float: unset !important;
  }
}

.material-icons {
  vertical-align: middle;
  font-size: 20px;
}

.outlined {
  font-size: 21px !important;
  vertical-align: bottom;
}

.container-fluid {
  padding-bottom: 0 !important;
}

button:focus {
  outline: 0 !important;
}

.btn-primary {
  background-color: rgb(var(--primary-color)) !important;
  border-color: rgb(var(--primary-color)) !important;
  color: white;
}

.btn-danger {
  background-color: inherit !important;
  border-color: inherit !important;
  color: #dc3545 !important;
  border: 0 !important;
  padding: 0 !important;
  text-align: center !important;
  transition: inherit !important;
}

.btn-warning {
  background-color: inherit !important;
  border-color: inherit !important;
  color: #ffc107 !important;
  border: 0 !important;
  padding: 0 !important;
  text-align: center !important;
  transition: inherit !important;
}

.btn-blue {
  background-color: inherit !important;
  border-color: inherit !important;
  color: rgb(var(--primary-color)) !important;
  border: 0 !important;
  padding: 0 !important;
  text-align: center !important;
  transition: inherit !important;
}

.btn-success {
  background-color: inherit !important;
  border-color: inherit !important;
  color: #28a745 !important;
  border: 0 !important;
  padding: 0 !important;
  text-align: center !important;
  transition: inherit !important;
}

.btn-info {
  background-color: inherit !important;
  border-color: inherit !important;
  color: #17a2b8 !important;
  border: 0 !important;
  padding: 0 !important;
  text-align: center !important;
  transition: inherit !important;
}

label {
  margin-bottom: 0 !important;
}

.property-img-height-list {
  display: none !important;
}

table.table-hover tbody tr {
  line-height: 2.2em !important;
  height: 2.2em !important;
}

.navbar {
  padding: 0 !important;
}

.mat-drawer-container {
  background-color: #ebebeb !important;
}

tbody > tr:nth-child(2n + 1) {
  background-color: #ffffff;
}

.navbar-brand {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

@keyframes pampalluguetes {
  0% {
    background: inherit;
  }
  50% {
    background-color: rgb(var(--primary-color));
  }
  100% {
    background: inherit;
  }
}

#loadingModal .modal-content,
#loadingModal .modal-body {
  background-color: inherit !important;
  border: 0 !important;
}

th {
  font-weight: 400;
}

tr,
.input-padding {
  font-weight: 300;
}

.btnjan i {
  font-size: 13px !important;
  background-color: rgb(var(--primary-color)) !important;
  border: 1.5px solid rgb(var(--primary-color)) !important;
  padding: 3.5px;
  border-radius: 3em;
  color: #fff !important;
}

.margin-left-right {
  margin-top: 0 !important;
}

.background-gradient {
  background-color: rgba(99, 131, 167, 0.3) !important;
  border-radius: 5px !important;
}

.form-control {
  font-size: 14px !important;
}

.label1 {
  font-size: 15px !important;
}

.cal-open-day-events {
  color: #3c3c3c !important;
  background-color: rgba(var(--primary-color), 0.9) !important;
  box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.2) !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  border-radius: 5px !important;
}

.second-row-new-event input {
  border-bottom: 1px solid #949494;
}

.mat-tab-label {
  min-width: auto !important;
  padding: 0 10px !important;
}

.cal-week-view .cal-event,
.cal-day-view .cal-event {
  border-top-width: 0.8em;
  border-radius: 2px !important;
  background-color: #fff !important;
  color: #000000;
}

.cal-week-view .customEventClass,
.cal-day-view .customEventClass {
  opacity: 0.4;
  /*background: #17c33e;*/
}

.cal-week-view .foreignEventClass,
.cal-day-view .foreignEventClass {
  /*opacity: 0.4;*/
  mix-blend-mode: difference;
  /*background: #17c33e;*/
}

.button-bottom-1 button {
  width: 100%;
}

.dialog-footer-2 button,
.dialog-footer-1 button {
  width: 49%;
}

.dialog-footer-3 button {
  width: 32%;
}

.dialog-footer-4 button {
  width: 24%;
}

.dialog-footer-2 button,
.dialog-footer-1 button,
.dialog-footer-3 button {
  color: rgb(var(--primary-color));
  border: 1px solid rgb(var(--primary-color));
  background: #fff;
  border-radius: 0.25rem;
  float: right;
  margin-top: 1em;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.dialog-footer-2 button:hover,
.dialog-footer-3 button:hover {
  color: #fff;
  background: rgb(var(--primary-color));
}

/* CAMPS ORDENATS */

.row-expansion6 mat-form-field,
.row-expansion6 .mat-checkbox-pre,
.row-expansion6 .buit {
  width: 13%;
  margin-right: 3.5% !important;
  margin-left: 0 !important;
  display: inline-block;
  margin-top: 0.6em;
  vertical-align: middle;
}

.row-expansion5 mat-form-field,
.row-expansion5 .mat-checkbox-pre,
.row-expansion5 .buit {
  width: 17%;
  margin-right: 3% !important;
  margin-left: 0 !important;
  display: inline-block;
  margin-top: 0.6em;
  vertical-align: middle;
}

.row-expansion4 mat-form-field,
.row-expansion4 .mat-checkbox-pre,
.row-expansion4 .buit {
  width: 22%;
  margin-right: 3% !important;
  margin-left: 0 !important;
  display: inline-block;
  margin-top: 0.6em;
  vertical-align: middle;
}

.row-expansion3 mat-form-field,
.row-expansion3 .mat-checkbox-pre,
.row-expansion3 .buit {
  width: 30%;
  margin-right: 3% !important;
  margin-left: 0 !important;
  display: inline-block;
  margin-top: 0.6em;
  vertical-align: middle;
}

.row-expansion2 mat-form-field,
.row-expansion2 .mat-checkbox-pre,
.row-expansion2 .buit {
  width: 47%;
  margin-right: 3% !important;
  margin-left: 0 !important;
  display: inline-block;
  margin-top: 0.6em;
  vertical-align: middle;
}

.row-expansion1 mat-form-field,
.row-expansion1 .mat-checkbox-pre,
.row-expansion1 .buit {
  width: 100%;
  margin-right: 0% !important;
  margin-left: 0 !important;
  display: inline-block;
  margin-top: 0.6em;
  vertical-align: middle;
}

.row-expansion7,
.row-expansion6,
.row-expansion5,
.row-expansion4,
.row-expansion3,
.row-expansion2,
.row-expansion1,
.rowrapper {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  flex-direction: row;
}

@media (max-width: 900px) {
  .row-expansion7 mat-form-field,
  .row-expansion7 .mat-checkbox-pre,
  .row-expansion7 .buit,
  .row-expansion6 mat-form-field,
  .row-expansion6 .mat-checkbox-pre,
  .row-expansion6 .buit,
  .row-expansion5 mat-form-field,
  .row-expansion5 .mat-checkbox-pre,
  .row-expansion5 .buit,
  .row-expansion4 mat-form-field,
  .row-expansion4 .mat-checkbox-pre,
  .row-expansion4 .buit,
  .row-expansion3 mat-form-field,
  .row-expansion3 .mat-checkbox-pre,
  .row-expansion3 .buit,
  .row-expansion2 mat-form-field,
  .row-expansion2 .mat-checkbox-pre,
  .row-expansion2 .buit {
    width: 100% !important;
    margin-right: 0% !important;
  }
}
/* ACABA CAMPS ORDENATS */

::-webkit-scrollbar {
  width: 0;
  height: 15px;
}

::-webkit-scrollbar:hover {
  border-top: 1px solid #888;
  border-left: 1px solid #888;
}

::-webkit-scrollbar-thumb {
  background: #8888;
  border-radius: 6px;
  border: 1px solid #888;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-thumb:hover {
  background: #222 !important;
  border: 1px solid #222 !important;
}

::-webkit-scrollbar-track:hover {
  background-color: #0000001a !important;
}

/**/

.mat-stepper-horizontal,
.mat-stepper-vertical,
.cliente-buscador {
  font-size: 14px;
}

table {
  width: 100%;
}

.taulesgrans th,
.taulesgrans td {
  text-align: left;
  padding: 0 8px 1px 8px !important;
}

.taulesgrans thead tr {
  line-height: 2em;
  color: #fff;
  background: rgb(var(--primary-color));
}

.taulesgrans th {
  line-height: 2em;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 11px;
}

.taulesgrans .buttons-head {
  display: flex;
  animation: pampalluguetes 1s linear;
  animation-fill-mode: both;
  animation-iteration-count: 8;
}

.taulesgrans .buttons-head button {
  width: 50%;
  margin: 0;
  background-color: inherit;
  color: #fff;
  border: 0;
  padding: 0;
}

.taulesgrans table {
  border-left: 1px solid rgba(var(--primary-color), 0.9)30;
  border-right: 1px solid rgba(var(--primary-color), 0.9)30;
}

.taulesgrans tbody tr {
  line-height: 2em;
  height: auto;
  padding: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: rgba(0, 0, 0, 0.025);
  animation-delay: 0.02s;
}

.taulesgrans tbody tr:nth-child(2n + 1) {
  background-color: #fff0 !important;
  animation-delay: 0s !important;
}

.taulesgrans tbody tr:hover {
  background-color: rgb(var(--primary-color)) !important;
}

.taulesgrans tbody td {
  /*color: rgba(51,51,51,0.44);*/
  vertical-align: initial;
  text-transform: uppercase;
  font-size: 12px;
}

.mat-sort-header-button,
.mat-header-cell {
  text-transform: uppercase;
  font-size: 10px;
}

.taulesgrans tbody tr:hover td {
  color: #fff !important;
  transition: all 0.08s ease-in-out;
}

.taulesgrans .buttons-body {
  display: flex;
}

.taulesgrans i {
  font-size: 15px !important;
}

.taulesgrans .buttons-body button {
  margin: 0;
  padding-right: 5% !important;
}

/*td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type,
th.mat-header-cell:last-of-type {
  padding-right: 0 !important;
}*/

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  border: 0 !important;
}

.taulesgrans tr.mat-header-row {
  height: auto !important;
}

.mat-progress-spinner.mat-progress-spinner-indeterminate-animation circle {
  animation-duration: 5.5s !important;
  stroke-width: 5% !important;
}

.loader-spinner0 {
  background: #ffffffb0;
  width: calc(87vw - 1.2vw);
  min-height: calc(100vh - 0.6vw - 28px);
  height: 100%;
  z-index: 999;
  position: absolute !important;
  transform: translate(-50%, -50%) !important;
  top: calc(50% - 0px) !important;
  left: calc(50% - 0px) !important;
  visibility: visible !important;
  opacity: 1 !important;
}

.loader-spinner0-out {
  visibility: hidden !important;
  transition: 0.2s !important;
  opacity: 0 !important;
}

.loader-spinner {
  z-index: 999;
  position: absolute !important;
  transform: translate(-50%, -50%) !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 20em;
  background: rgba(var(--primary-color), 0.4);
  top: calc(50% - 30px) !important;
  left: calc(50% - 30px) !important;
}

.loader-spinner svg {
  width: 60px !important;
  height: 60px !important;
}

.loader-spinner svg circle {
  stroke: rgb(var(--primary-color)) !important;
  animation-duration: 5s !important;
  stroke-width: 2% !important;
}

.loader-spinner-init .loader-spinner svg circle {
  stroke:rgb( var(--tertiary-color)) !important;
}

.loader-spinner-landing {
  left: calc(50% - 30px) !important;
}

.loader2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yes-option .mat-radio-outer-circle,
.yes-option .mat-radio-inner-circle {
  border-color: #17c33e !important;
}

.no-option .mat-radio-outer-circle,
.no-option .mat-radio-inner-circle {
  border-color: #dc3544 !important;
}

.yes-option.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.yes-option.mat-accent .mat-radio-inner-circle {
  border-color: #b3b3b3 !important;
  background: #17c33e !important;
}

.no-option.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.no-option.mat-accent .mat-radio-inner-circle {
  border-color: #b3b3b3 !important;
  background: #dc3544 !important;
}

.no-defined-option.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.no-defined-option.mat-accent .mat-radio-inner-circle {
  background: #e0e0e0 !important;
  border-color: #b3b3b3 !important;
}

.no-defined-option .mat-radio-container {
  display: none !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 25px !important;
}

.report-portales .mat-slide-toggle-bar {
  width: 50px !important;
  height: 10px !important;
}

.report-portales .mat-slide-toggle-thumb-container {
  top: 0 !important;
}

.report-portales .mat-slide-toggle-thumb {
  height: 10px !important;
  width: 10px !important;
}

.report-portales
  .mat-slide-toggle.mat-checked
  .mat-slide-toggle-thumb-container {
  transform: translate3d(39px, 0, 0) !important;
}

.report-portales .mat-slide-toggle .mat-slide-toggle-ripple {
  top: calc(50% - 13px) !important;
  left: calc(50% - 13px) !important;
  height: 16px !important;
  width: 16px !important;
}

.mat-tab-header {
  height: 3em;
  position: absolute !important;
  z-index: 0;
  background: #fff;
  width: 100%;
}

.login .mat-tab-header {
  height: auto;
  position: relative !important;
  z-index: 999;
  background: #fff;
  width: 100%;
}

.mat-tab-body-wrapper {
  margin-top: 3em;
}

.mat-tab-label-container {
  padding: 0 1em;
}

.mat-tab-body-wrapper {
  padding: 0;
}

.mat-form-field-prefix,
.mat-form-field-suffix {
  font-size: 13px !important;
  color: #949494 !important;
}

.group-2-tabs .mat-tab-label {
  width: 50%;
}

.group-3-tabs .mat-tab-label {
  width: 33.33%;
}

.group-tab-dialog .mat-tab-header {
  width: auto;
}

.mat-tab-label {
  padding: 0;
}

.mat-tab-label i {
  padding-left: 0.7em;
}

.mat-tab-label span {
  padding-right: 0.7em;
  padding-left: 0.7em;
}

tr {
  cursor: pointer;
}

.flatpickr-calendar {
  position: fixed;
}

.mat-expansion-panel-header:not([aria-disabled="true"]) {
  background: rgba(0, 0, 0, 0.04);
}

/*.stepper .mat-button {
  margin-right: 1.5em !important;
  margin-top: 2.5em !important;
}*/

textarea.mat-input-element.cdk-textarea-autosize {
  min-height: 20px !important;
}

.taulesgrans {
  background: #fbfbfb;
}

.content-offers {
  padding: 20px 24px !important;
}

.blue-tag {
  color: #8da8f2;
  font-size: 14px;
}

.mat-placeholder-required {
  color: red !important;
}

.mat-tooltip-panel {
  box-shadow: inherit !important;
}

.input-filter {
  font-size: 14px;
  width: 100%;
}

.bottom-table {
  min-height: 56px;
  display: flex;
  background: #fff;
  border-top: 1px solid #c7c7c7;
}

.bottom-table2 {
  width: 50%;
  padding-left: 1vw;
}

.bottom-table mat-paginator .mat-paginator-container {
  justify-content: center;
}

.cal-all-day-events {
  height: fit-content !important;
}

.table-dialog .mat-dialog-container {
  padding: 0 !important;
  border: 1px solid #c9c9c9 !important;
}

.addBtn {
  position: absolute;
  width: auto;
  padding: 0.2em 1em;
  margin-top: 4px;
}

.dates-inputs input {
  width: 100% !important;
  border: 0 !important;
  cursor: pointer !important;
}

.btn-outline-danger,
.btn-outline-success,
.btn-outline-warning {
  background: white !important;
}
.btn-outline-danger:hover {
  background-color: #dc3545 !important;
}
.btn-outline-success:hover {
  background-color: #28a745 !important;
}
.btn-outline-warning:hover {
  background-color: #ffc107 !important;
}
.btn-outline-warning:disabled,
.btn-outline-success:disabled,
.btn-outline-danger:disabled {
  background-color: white !important;
}

img.fotoEvento {
  height: 30px;
  width: 30px;
  border-radius: 20%;
  border: 1px solid rgb(var(--primary-color));
  object-fit: cover;
}

img.fotoEvento2 {
  height: 40px;
  width: 40px;
  border-radius: 20%;
  border: 1px solid rgb(var(--primary-color));
  object-fit: cover;
}

img.fotoPerfil {
  height: 2em;
  width: 2em;
  border-radius: 100%;
  border: 1px solid rgb(var(--primary-color));
  object-fit: contain;
  background: #fff;
}

.cell-totals {
  margin: 5px;
  text-align: center;
}
.badge {
  margin-right: 5px;
}

.drag-active {
  position: relative;
  z-index: 1;
  pointer-events: none;
}
.drag-over {
  background-color: #eee;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cal-all-day-events .cal-event {
  height: 70px !important;
  background: #17c33e;
}

.cal-all-day-events .cal-events-row {
  height: 73px !important;
}

.material-icons.color_yellow {
  color: rgb(var(--secondary-color));
}
.material-icons.color_red {
  color: #bf241e;
}
.material-icons.color_green {
  color: #049d31;
}

.mat-expansion-panel-header-title {
  text-transform: uppercase !important;
}

.input-length-count {
  text-align: right;
  color: grey;
  font-size: 0.7rem;
}

.matFormFieldForDatePicker {
  background-color: #ffffff;
  box-shadow: inherit;
  border: 0;
  color: rgb(var(--primary-color));
  width: 100%;
}

.cal-header {
  display: flex;
  flex-flow: wrap;
}

.cal-header b {
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  text-align: center;
}

.cal-header span {
  text-transform: capitalize;
  font-size: 12px;
  width: 100%;
  text-align: center;
}

.cal-header br {
  display: none;
}

.top-home mat-form-field .mat-form-field-wrapper {
  padding: 0;
}

.top-home mat-form-field .mat-form-field-wrapper .mat-form-field-infix {
  padding: 0;
  border: 0;
}

.cal-week-view .cal-time {
  font-weight: 300 !important;
  /*padding-top: calc(50% - 10px) !important;*/
  font-size: 14px !important;
}

.category-circle {
  height: 0.6em !important;
  width: 0.6em !important;
  margin-right: 0px !important;
}

mwl-calendar-event-title span {
  text-transform: uppercase;
  font-size: 11px;
}

.mat-checkbox-layout .mat-form-field-disabled,
.mat-checkbox-layout .mat-checkbox-label {
  text-transform: uppercase;
  font-size: 11px;
}

.mat-checkbox-frame {
  border-radius: 3px !important;
}

.mat-checkbox-inner-container {
  width: 13px !important;
  height: 13px !important;
}

.mat-radio-outer-circle,
.mat-radio-container,
.mat-radio-inner-circle {
  width: 15px !important;
  height: 15px !important;
}

.mat-radio-label-content {
  text-transform: uppercase;
  font-size: 12px;
}

.mat-expansion-panel-header-description {
  text-transform: uppercase;
  font-size: 12px;
}

.mat-expansion-panel-header-title {
  text-transform: uppercase;
  font-size: 13px;
}

.mat-expansion-panel-header-title h4 {
  text-transform: uppercase;
  font-size: 15px;
}

.mat-expansion-panel-header-title h4 i {
  font-size: 15px;
  vertical-align: text-top;
}

.mat-expansion-panel-header {
  min-height: 44px !important;
  height: auto !important;
}

.mat-tab-label span,
.mat-tab-label .mat-tab-label-content {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
}

.mat-tab-label i {
  font-size: 14px;
}

.mat-action-row button.mat-button-base,
.mat-action-row button.mat-mdc-button-base {
  text-transform: uppercase;
  font-size: 11.5px;
  line-height: 11px;
  padding: 8px;
}

/*.mat-menu-panel button {
  text-transform: uppercase;
  font-size: 11px;
}

.mat-menu-panel button i {
  font-size: 12px;
}*/

.mat-tab-body-content {
  overflow: hidden !important;
}

#share-tour-form .mat-form-field-wrapper {
  width: 100% !important;
}

.red-info-div {
  background-color:rgb( var(--tertiary-color));
  color: #ffffff;
}

.yellow-info-div {
  background-color: rgb(var(--secondary-color));
}

.green-info-div {
  background-color: #90ee90;
}

.green-info-div,
.yellow-info-div,
.red-info-div {
  font-weight: bold;
  text-transform: uppercase;
}

.tabs-bill-2 mat-tab-header,
.tabs-bill mat-tab-header {
  position: relative !important;
}
.tabs-bill-2 .mat-tab-label-container,
.tabs-bill .mat-tab-label-container {
  padding-left: 0 !important;
}

.tabs-bill .mat-tab-body-wrapper {
  margin-top: 0 !important;
}

.tabs-bill-2 .mat-tab-body-wrapper {
  margin-top: 1em !important;
}

.mat-form-field-label-wrapper {
  top: -0.5em !important;
}

.mat-form-field-disabled input.mat-input-element,
.mat-select-value-text,
.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  margin-top: -0.0625em !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.mat-paginator-page-size .mat-select-value-text {
  font-size: 12px !important;
}

.mat-select-value-text,
.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  text-transform: capitalize !important;
}

.mat-bottom-sheet-container {
  overflow-x: hidden !important;
}

.rating .spinner-0 .mat-progress-spinner circle {
  /*stroke: rgb(var(--secondary-color)) !important;*/
  stroke-width: 3.5% !important;
}

p-carousel {
  width: 100% !important;
}

.ui-carousel-dots-container {
  display: none !important;
}

.ui-carousel-item {
  text-align: center;
}

.no-margin {
  margin: 0 !important;
}

.cdk-overlay-container:has(
    .overAllDialog,
    .mat-select-panel-wrap,
    .mat-autocomplete-panel,
    .mat-snack-bar-container,
    .mat-datepicker-popup
  ) {
  z-index: 999999999999999;
}

body:has(#externalURL)
  .cdk-overlay-container:has(
    .overAllDialog,
    .mat-select-panel-wrap,
    .mat-autocomplete-panel,
    .mat-snack-bar-container
  )
  .cdk-global-overlay-wrapper {
  display: none !important;
}

body:has(#externalURL)
  .cdk-overlay-container:has(
    .overAllDialog,
    .mat-select-panel-wrap,
    .mat-autocomplete-panel,
    .mat-snack-bar-container
  )
  .cdk-global-overlay-wrapper:has(
    .overAllDialog,
    .mat-select-panel-wrap,
    .mat-autocomplete-panel,
    .mat-snack-bar-container
  ) {
  display: flex !important;
}

.cdk-overlay-backdrop {
  opacity: 0 !important;
}

.cdk-global-overlay-wrapper {
  background: rgba(0, 0, 0, 0.288);
}

.cdk-global-overlay-wrapper:has(
    .mat-select-panel-wrap,
    .mat-autocomplete-panel,
    .mat-snack-bar-container
  ) {
  background: rgb(0 0 0 / 0%);
}

.custom-mat-drawer-content {
  height: 100% !important;
  width: 100% !important;
  background: black;
  overflow: hidden !important;
}

.registry-user .mat-horizontal-stepper-header-container,
.out-header-stepper .mat-horizontal-stepper-header-container {
  display: none !important;
}

/*.mat-form-field-appearance-outline .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 0 !important;
}*/

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0;
}

.mat-form-field-hide-placeholder .mat-form-field-label-wrapper {
  top: -1.3em !important;
}

.mat-form-field-should-float .mat-form-field-label-wrapper {
  top: -1em !important;
}

.loggin .mat-form-field-appearance-outline .mat-form-field-flex,
.tab-group-categoria .mat-form-field-appearance-outline .mat-form-field-flex {
  background: #fff;
}

.loggin .mat-input-element,
.tab-group-categoria .mat-input-element {
  vertical-align: text-top;
  font-size: 13px;
  color: #000;
}

.loggin .mat-form-field-appearance-outline .mat-form-field-infix,
.loggin .mat-form-field-appearance-outline .mat-form-field-wrapper,
.tab-group-categoria .mat-form-field-appearance-outline .mat-form-field-infix,
.tab-group-categoria
  .mat-form-field-appearance-outline
  .mat-form-field-wrapper {
  padding: 0 !important;
  margin: 0 !important;
  border-top: 0.5em solid transparent;
}

.loggin .mat-form-field-appearance-outline .mat-form-field-outline,
.loggin .mat-form-field-appearance-outline .mat-form-field-prefix,
.loggin .mat-form-field-appearance-outline .mat-form-field-suffix,
.tab-group-categoria .mat-form-field-appearance-outline .mat-form-field-outline,
.tab-group-categoria .mat-form-field-appearance-outline .mat-form-field-prefix,
.tab-group-categoria .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}

.loggin .mat-form-field-prefix .mat-icon-button,
.loggin .mat-form-field-suffix .mat-icon-button,
.tab-group-categoria .mat-form-field-prefix .mat-icon-button,
.tab-group-categoria .mat-form-field-suffix .mat-icon-button {
  height: auto !important;
}

.loggin .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper,
.tab-group-categoria
  .mat-form-field-appearance-outline
  .mat-form-field-subscript-wrapper {
  position: relative;
  margin: 0;
  padding: 3px 0 0 0;
}

.tab-group-categoria .mat-form-field-suffix button {
  border: inherit;
  background: inherit;
  vertical-align: super;
}

.tab-group-categoria .rellenar-flat {
  display: flex;
  align-items: center;
}

.tab-group-categoria .rellenar-flat .rellenar-flat-text {
  margin-right: 10px;
  font-weight: bold;
  font-size: 15px;
}

.tabla-puntuaciones .mat-progress-bar-background {
  fill: #353535 !important;
}

.tabla-puntuaciones .mat-expansion-panel,
.tabla-configuration .mat-expansion-panel {
  width: 100%;
  background: #222;
  transition: 0.2s;
  border-radius: 0 !important;
}

.tabla-puntuaciones .mat-expansion-panel:hover,
.tabla-configuration .mat-expansion-panel:hover {
  border-bottom: 1px solid #383838;
  border-top: 1px solid #383838;
}

.tabla-puntuaciones .mat-progress-bar-buffer {
  background-color: #353535 !important;
}

.tabla-puntuaciones .mat-progress-bar-fill::after {
  background-color: rgb(var(--secondary-color)) !important;
}

.tabla-puntuaciones .mat-tab-header {
  height: auto;
  position: relative !important;
  z-index: 999;
  background: #000;
  width: 100%;
}

.tabla-puntuaciones .mat-ink-bar {
  background: #fff !important;
  padding-left: 0em !important;
  width: 33.33% !important;
}

.tabla-puntuaciones .mat-tab-label-container {
  padding-left: 0em !important;
}

.tabla-puntuaciones .mat-tab-label {
  width: 33.33% !important;
  color: #fff !important;
}

.tabla-puntuaciones .mat-tab-label-active {
  opacity: 1 !important;
}

.tabla-puntuaciones .mat-tab-body-wrapper {
  margin-top: 0 !important;
}

.tabla-puntuaciones mat-expansion-panel mat-expansion-panel-header,
.tabla-configuration mat-expansion-panel mat-expansion-panel-header {
  background: #222222 !important;
  font-weight: 600 !important;
}

.tabla-puntuaciones .mat-expansion-panel:hover {
  background: #383838 !important;
}

.tabla-puntuaciones .mat-expansion-panel-header-title,
.tabla-puntuaciones .mat-expansion-panel-header-description,
.tabla-puntuaciones .mat-expansion-indicator::after,
.tabla-configuration .mat-expansion-panel-header-title,
.tabla-configuration .mat-expansion-panel-header-description,
.tabla-configuration .mat-expansion-indicator::after {
  color: #ffffff !important;
  transition: 0.2s;
}

.tabla-puntuaciones mat-expansion-panel:hover .mat-expansion-panel-header-title,
.tabla-puntuaciones
  mat-expansion-panel:hover
  .mat-expansion-panel-header-description,
.tabla-puntuaciones mat-expansion-panel:hover .mat-expansion-indicator::after,
.tabla-configuration
  mat-expansion-panel:hover
  .mat-expansion-panel-header-title,
.tabla-configuration
  mat-expansion-panel:hover
  .mat-expansion-panel-header-description,
.tabla-configuration mat-expansion-panel:hover .mat-expansion-indicator::after {
  color: #fff !important;
}

.tabla-puntuaciones .mat-expansion-panel-spacing,
.tabla-configuration .mat-expansion-panel-spacing {
  margin: 5px 0 !important;
  border-radius: 0 !important;
}

.tabla-puntuaciones .mat-expansion-panel-content {
  background: #353535 !important;
  color: #fff !important;
}

.tabla-configuration .mat-expansion-panel-content {
  background: #fff !important;
}

.tabla-puntuaciones .mat-expansion-panel-body,
.tabla-configuration .mat-expansion-panel-body {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.puntuation-inmo mat-progress-spinner,
.puntuation-inmo svg {
  width: 170px !important;
  height: 170px !important;
}

p-carousel .pi:before {
  color: #fff !important;
  display: none !important;
}

p-carousel .ui-button {
  background: #0000 !important;
  border: inherit !important;
}

p-carousel .ui-button:focus {
  box-shadow: inherit !important;
}

p-carousel .ui-button .pi {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

p-carousel .ui-button:focus .pi {
  font-size: 1.5em !important;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
}

p-carousel .ui-carousel-prev .pi {
  border-right: 20px solid white;
  float: left;
}

p-carousel .ui-carousel-next .pi {
  border-left: 20px solid white;
  float: right;
}

p-carousel .ui-carousel-prev:focus .pi {
  border-right: 22px solid white;
}

p-carousel .ui-carousel-next:focus .pi {
  border-left: 22px solid white;
}

.library p-carousel:hover .ui-carousel-item-start .item:hover .img-carroussel {
  margin-left: 0em !important;
}

.library p-carousel:hover .ui-carousel-item-end .item:hover .img-carroussel {
  margin-left: 0em !important;
}

.progress-red {
  position: absolute !important;
  color:rgb( var(--tertiary-color)) !important;
}

.progress-red .mat-progress-bar-fill::after {
  background-color:rgb( var(--tertiary-color)) !important;
}

.progress-yellow {
  position: absolute !important;
  color: rgb(var(--secondary-color)) !important;
}

.progress-yellow .mat-progress-bar-fill::after {
  background-color: rgb(var(--secondary-color)) !important;
}

.progress-blue {
  position: absolute !important;
  color: rgb(var(--primary-color)) !important;
}

.progress-blue .mat-progress-bar-fill::after {
  background-color: rgb(var(--primary-color)) !important;
}

.spinner-red circle {
  stroke:rgb( var(--tertiary-color)) !important;
}

.spinner-yellow circle {
  stroke: rgb(var(--secondary-color)) !important;
}

.spinner-blue circle {
  stroke: rgb(var(--primary-color)) !important;
}

.inmo-list .mat-option-text {
  text-transform: lowercase !important;
}

.inmo-list .mat-option-text:first-letter {
  text-transform: uppercase !important;
}

.ui-carousel-items-content {
  overflow-x: auto !important;
}

.lista-inmos .ui-carousel-items-content {
  overflow-x: hidden !important;
}

@media (max-width: 500px) {
  .contenerdor-imagenes .ui-button {
    display: inline-block !important;
  }
}

.lista-inmos .ui-carousel .ui-carousel-content .ui-carousel-prev,
.lista-inmos .ui-carousel .ui-carousel-content .ui-carousel-next {
  align-self: flex-start !important;
  top: 1em !important;
}

.puntuation-paginator .mat-paginator-container {
  min-height: auto !important;
}

.puntuation-paginator .mat-paginator-page-size {
  display: none !important;
}

.mat-sidenav-content,
.mat-drawer-container {
  background: #fff0 !important;
}

.padre-tabs .inmo-profile .mat-tab-header {
  background: #000000ba;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  height: 3.2em;
  position: relative !important;
}

.padre-tabs .mat-tab-header {
  background: #000000ba;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  height: 3em;
  position: fixed !important;
}

.inmo-profile .mat-tab-label .mat-tab-label-content,
.padre-tabs .mat-tab-label .mat-tab-label-content {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}

.inmo-profile .mat-tab-label,
.padre-tabs .mat-tab-label {
  color: #ffffff;
  background-color: inherit !important;
}

.inmo-profile .mat-tab-body-wrapper {
  margin: 0 !important;
}

.inmo-profile .mat-tab-group.mat-primary .mat-ink-bar,
.inmo-profile .mat-tab-nav-bar.mat-primary .mat-ink-bar,
.padre-tabs .mat-tab-group.mat-primary .mat-ink-bar,
.padre-tabs .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ffffff !important;
}

.inmo-profile .mat-tab-label .mat-tab-label-content .rounded,
.padre-tabs .mat-tab-label .mat-tab-label-content .rounded {
  font-size: 24px !important;
}

.mat-tooltip {
  background: #212121 !important;
  text-transform: uppercase !important;
  font-weight: 700;
  /*font-size: 10px !important;
  border-radius: 0 !important;*/
  border: 0.2px solid #e4e4e4;
  // font-family: "Montserrat", sans-serif !important;
}

.rating .spinner-0 .mat-progress-spinner circle {
  stroke:rgb( var(--tertiary-color));
  stroke-width: 3.5% !important;
}
.rating .spinner-1 .mat-progress-spinner circle {
  stroke: rgb(var(--secondary-color));
  stroke-width: 3.5% !important;
}
.rating .spinner-2 .mat-progress-spinner circle {
  stroke: rgb(var(--primary-color));
  stroke-width: 3.5% !important;
}
.rating .number-rating {
  position: relative;
  top: -60px;
  /*left: 30px;*/
  left: 0px;
  text-align: center;
  color: #fff;
  font-weight: bolder;
  font-size: 15px;
}

.lista-inmos-0 .mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: #fff !important;
}
.lista-inmos-0 .mat-paginator {
  background: #000;
  width: 100%;
}
.lista-inmos-0 .mat-paginator .mat-paginator-container {
  justify-content: center;
  min-height: 0;
}
.lista-inmos-0 .mat-paginator .mat-paginator-page-size {
  display: none;
}
.mat-icon-button[disabled][disabled] {
  color: #ffffff40;
}

.inmo-profile
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .ui-carousel-container
  .ui-carousel-items-content
  .ui-carousel-item
  img {
  height: 18.5vw;
}

.inmo-profile
  .cont-1
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .ui-carousel-container
  .ui-carousel-items-content
  .ui-carousel-item
  img,
.inmo-profile .cont-1 .each-card .each-card-1 .viewer .imagen-nocarousel1 {
  height: 21.2vw !important;
}

.inmo-profile
  .cont-2
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .ui-carousel-container
  .ui-carousel-items-content
  .ui-carousel-item
  img,
.inmo-profile .cont-2 .each-card .each-card-1 .viewer .imagen-nocarousel1 {
  height: 31.3vw !important;
}

.inmo-profile
  .cont-3
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .ui-carousel-container
  .ui-carousel-items-content
  .ui-carousel-item
  img,
.inmo-profile .cont-3 .each-card .each-card-1 .viewer .imagen-nocarousel1 {
  height: 62.3vw !important;
}

.inmo-profile .imagenes-carousel-flat-card .ui-carousel-content,
.inmo-profile
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .ui-carousel-container {
  padding: 0;
}

.inmo-profile
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .similar-flats-carrousel
  .ui-carousel-prev,
.inmo-profile
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .similar-flats-carrousel
  .ui-carousel-next,
.similar-flats-carrousel .ui-carousel-prev:not(.ui-state-disabled):hover,
.inmo-profile
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .similar-flats-carrousel
  .ui-carousel-content
  .ui-carousel-next:not(.ui-state-disabled):hover {
  color: #fff !important;
}

.inmo-profile
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .ui-carousel-prev {
  position: absolute;
  z-index: 9999;
  width: 25px !important;
  height: 25px !important;
  line-height: 12.5px !important;
  box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.2) !important;
  background: #000000ba !important;
  border: 1px solid #000 !important;
  top: 75%;
  border-radius: 50%;
  visibility: hidden;
  left: 10px !important;
  padding: 0;
  min-width: 0;
  color: #fff !important;
}

.inmo-profile
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .ui-carousel-prev
  span,
.inmo-profile
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .ui-carousel-next
  span {
  font-size: 15px;
  border: inherit !important;
  width: 100%;
}

.inmo-profile
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .ui-carousel-next {
  position: absolute;
  z-index: 9999;
  width: 25px !important;
  height: 25px !important;
  line-height: 12.5px !important;
  box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.2) !important;
  background: #000000ba !important;
  border: 1px solid #000 !important;
  top: 75%;
  border-radius: 50%;
  visibility: hidden;
  right: 10px !important;
  padding: 0;
  min-width: 0;
  color: #fff !important;
}

.inmo-profile
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .ui-carousel-items-content
  .ui-carousel-item
  img {
  width: 100%;
  object-fit: cover;
}

.inmo-profile
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .ui-carousel-items-content
  .ui-carousel-item
  div {
  position: absolute;
  top: 0;
}

.inmo-profile
  .imagenes-carousel-flat-card
  .ui-carousel-content
  .ui-carousel-dots-container {
  display: none;
}

.inmo-profile
  .imagenes-carousel-flat-card:hover
  .ui-carousel-content
  .ui-carousel-container
  .ui-carousel-prev,
.inmo-profile
  .imagenes-carousel-flat-card:hover
  .ui-carousel-content
  .ui-carousel-container
  .ui-carousel-next {
  visibility: visible;
}

.no-hover {
  display: block !important;
  visibility: visible !important;
}

/*.inmo-profile p-carousel .pi:before {
  color: inherit !important;
  display: inherit !important;
}*/

@media (max-width: 960px) {
  .inmo-profile
    .imagenes-carousel-flat-card:hover
    .ui-carousel-content
    .ui-carousel-container
    .ui-carousel-prev,
  .inmo-profile
    .imagenes-carousel-flat-card
    .ui-carousel-content
    .ui-carousel-container
    .ui-carousel-next {
    display: block !important;
    visibility: visible !important;
  }

  .inmo-profile
    .imagenes-carousel-flat-card
    .ui-carousel-content
    .ui-carousel-container
    .ui-carousel-next,
  .inmo-profile
    .imagenes-carousel-flat-card
    .ui-carousel-content
    .ui-carousel-container
    .ui-carousel-prev {
    width: 30px !important;
    height: 30px !important;
    top: 70%;
  }

  .inmo-profile
    .imagenes-carousel-flat-card
    .ui-carousel-content
    .ui-carousel-container
    .ui-carousel-next
    span,
  .inmo-profile
    .imagenes-carousel-flat-card
    .ui-carousel-content
    .ui-carousel-container
    .ui-carousel-prev
    span {
    font-size: 19px;
  }
  
  .loader-spinner0 {
    width: calc(100vw - 1.2vw);
    min-height: calc(100vh - 0.6vw - 28px - 2.6em);
  }
}

.all-chat .mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 !important;
  background: #fff;
  border-radius: 1.5em;
}

.all-chat .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0 0 1em 0 !important;
}

.all-chat .mat-form-field-appearance-outline .mat-form-field-prefix,
.all-chat .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0.75em;
}

.search-colaborators input.mat-input-element,
.search-colaborators
  .mat-form-field-should-float
  .mat-form-field-label-wrapper {
  color: #fff;
}
.search-colaborators
  .mat-form-field-appearance-outline
  .mat-form-field-wrapper {
  padding: 0 !important;
}
.search-colaborators .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0 1em !important;
  border-top: 0 !important;
}
.search-colaborators .mat-form-field-appearance-outline .mat-form-field-flex {
  border: 0.15em solid #949494;
  background: #000000a6;
  border-radius: 10em !important;
}
.search-colaborators
  .mat-form-field-appearance-outline.mat-focused
  .mat-form-field-flex,
.search-colaborators
  .mat-form-field-appearance-outline:hover
  .mat-form-field-flex {
  border: 0.15em solid #fff;
}
.search-colaborators
  .mat-form-field-appearance-outline.mat-focused
  .mat-form-field-flex
  mat-icon,
.search-colaborators
  .mat-form-field-appearance-outline:hover
  .mat-form-field-flex
  mat-icon {
  color: #fff;
}
.search-colaborators .mat-form-field-appearance-outline input::placeholder {
  color: #949494 !important;
}
.search-colaborators
  .mat-form-field-appearance-outline
  .mat-form-field-outline-thick,
.search-colaborators
  .mat-form-field-appearance-outline
  .mat-form-field-outline {
  display: none !important;
}

#registry-user .mat-form-field-wrapper {
  padding-bottom: 0.5em !important;
}

.fomular .mat-form-field-subscript-wrapper {
  margin-top: 1.1em !important;
}

.mat-progress-spinner.yellowSpinner circle,
.mat-spinner.yellowSpinner circle {
  stroke: rgb(var(--secondary-color)) !important;
}

.swapping .mat-list-base .mat-list-item .mat-list-item-content,
.swapping .mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0 !important;
}

.flatpickr-time {
  height: auto !important;
}

.black-field-type .mat-form-field-appearance-outline .mat-form-field-outline {
  background: #1f1f1f94 !important;
}
.black-field-type .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12) !important;
}
.black-field-type
  .mat-form-field-appearance-outline
  .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87) !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-focused
  .mat-form-field-outline-thick {
  color: #3f51b5 !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-focused.mat-accent
  .mat-form-field-outline-thick {
  color: #ff4081 !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-focused.mat-warn
  .mat-form-field-outline-thick {
  color: #f44336 !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
  .mat-form-field-outline-thick {
  color: #f44336 !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-form-field-disabled
  .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38) !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-form-field-disabled
  .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06) !important;
}
.black-field-type .mat-form-field-outline div {
  border-radius: 0 !important;
  border-color: #ffffff66 !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-outline-gap {
  border-top-color: transparent !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  font-weight: 300 !important;
  color: #fff !important;
  font-size: 10px !important;
}
.black-field-type .mat-form-field-label {
  color: #fff !important;
}
.black-field-type
  .mat-form-field.mat-form-field-invalid
  .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid
  .mat-form-field-label
  .mat-form-field-required-marker {
  color: #f44336 !important;
}
.black-field-type .mat-form-field-appearance-outline .mat-form-field-infix {
  font-size: 10px !important;
  font-weight: 700 !important;
  margin: 0.5em 0 !important;
}
.black-field-type .mat-form-field-appearance-outline input {
  color: #fff !important;
  letter-spacing: 0.5px !important;
  font-size: 11px !important;
}
.black-field-type button {
  border-radius: 0 !important;
  background: rgba(66, 103, 143, 0.58) !important;
  border: 1px solid rgba(66, 103, 143, 0.79) !important;
  float: right !important;
  font-size: 10px !important;
  font-weight: bolder !important;
  text-transform: uppercase !important;
  line-height: 26px !important;
  padding: 3px 8px 0 !important;
  color: #fff !important;
}

.black-field-type .mat-form-field-appearance-outline .mat-form-field-outline {
  background: #1f1f1f94 !important;
}
.black-field-type .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12) !important;
}
.black-field-type
  .mat-form-field-appearance-outline
  .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87) !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-focused
  .mat-form-field-outline-thick {
  color: #3f51b5 !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-focused.mat-accent
  .mat-form-field-outline-thick {
  color: #ff4081 !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-focused.mat-warn
  .mat-form-field-outline-thick {
  color: #f44336 !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
  .mat-form-field-outline-thick {
  color: #f44336 !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-form-field-disabled
  .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38) !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-form-field-disabled
  .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06) !important;
}
.black-field-type .mat-form-field-outline div {
  border-radius: 0 !important;
  border-color: #ffffff66 !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-outline-gap {
  border-top-color: transparent !important;
}
.black-field-type
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  font-weight: 300 !important;
  color: #fff !important;
  font-size: 10px !important;
}
.black-field-type .mat-form-field-label {
  color: #fff !important;
}
.black-field-type
  .mat-form-field.mat-form-field-invalid
  .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid
  .mat-form-field-label
  .mat-form-field-required-marker {
  color: #f44336 !important;
}
.black-field-type .mat-form-field-appearance-outline .mat-form-field-infix {
  font-size: 10px !important;
  font-weight: 700 !important;
  margin: 0.5em 0 !important;
}
.black-field-type .mat-form-field-appearance-outline input {
  color: #fff !important;
  letter-spacing: 0.5px !important;
  font-size: 11px !important;
}
.black-field-type button {
  border-radius: 0 !important;
  background: rgba(66, 103, 143, 0.58) !important;
  border: 1px solid rgba(66, 103, 143, 0.79) !important;
  float: right !important;
  font-size: 10px !important;
  font-weight: bolder !important;
  text-transform: uppercase !important;
  line-height: 26px !important;
  padding: 3px 8px 0 !important;
  color: #fff !important;
}
.black-field-type .mat-select-placeholder {
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
  text-transform: uppercase !important;
}
.black-field-type .mat-select-arrow,
.black-field-type .mat-select-value-text,
.black-field-type .mat-form-field.mat-focused.mat-primary .mat-select-arrow,
.black-field-type
  .mat-form-field.mat-focused.mat-primary
  .mat-select-value-text {
  color: #fff !important;
}

.mat-menu-item {
  color: #fff !important;
}

.mat-menu-item .material-icons {
  color: #fff !important;
  margin-right: 16px !important;
}

.mat-menu-item svg {
  color: #fff !important;
}

.mat-menu-panel {
  /*background: #383838 !important;
  border: 1px solid #333 !important;*/
  background: rgb(var(--primary-color)) !important;
  border: 1px solid rgba(var(--primary-color), 0.8) !important;
  border-radius: 7px !important;
}

.mat-menu-panel button.mat-menu-item:hover {
  background: #00000040 !important;
}

.mat-menu-item-submenu-trigger::after {
  color: #aaa !important;
}

.notis0 .mat-stepper-horizontal,
.notis0 .mat-stepper-vertical {
  background: inherit !important;
}

.icons-header .mat-badge-content {
  border: 2px solid #000000 !important;
  color: #000000 !important;
  background: rgb(var(--secondary-color)) !important;
  font-size: 12px !important;
  top: -3px !important;
  right: -8px !important;
  font-weight: bold !important;
  width: 20px !important;
  height: 20px !important;
  line-height: 18px !important;
}

.notis-menu .mat-badge-content {
  border: 2px solid #222222 !important;
  color: #222222 !important;
  background: rgb(var(--secondary-color)) !important;
  font-size: 12px !important;
  top: -6px !important;
  right: 5px !important;
  font-weight: bold !important;
  width: 20px !important;
  height: 20px !important;
  line-height: 18px !important;
}

.loading-window {
  position: fixed !important;
  width: 100vw;
  z-index: 9999999 !important;
  height: 3px !important;
}

.loading-window .mat-progress-bar-fill::after {
  background:rgb( var(--tertiary-color)) !important;
}

.loading-window .mat-progress-bar-buffer {
  background-color: #00000000 !important;
}

.loading-window .mat-progress-bar-background {
  fill: #00000000 !important;
}

.loading-structure {
  position: absolute !important;
  top: 5.2em !important;
  z-index: 9999999 !important;
  height: 3px !important;
}

.loading-structure .mat-progress-bar-fill::after {
  background:rgb( var(--tertiary-color)) !important;
}

.loading-structure .mat-progress-bar-buffer {
  background-color: #00000000 !important;
}

.loading-structure .mat-progress-bar-background {
  fill: #00000000 !important;
}

.each-noti .mat-step-header .mat-step-label.mat-step-label-active {
  color: #fff !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
}

.each-noti .mat-horizontal-stepper-header .mat-step-icon {
  border: 1px solid rgb(var(--secondary-color)) !important;
  color: rgb(var(--secondary-color)) !important;
  font-weight: bold !important;
}

.each-noti .mat-step-header .mat-step-icon-selected,
.each-noti .mat-step-header .mat-step-icon-state-done,
.each-noti .mat-step-header .mat-step-icon-state-edit {
  background: rgb(var(--secondary-color)) !important;
  color: #000 !important;
}

.each-noti .mat-step-header .mat-step-icon-state-done,
.each-noti .mat-step-header .mat-step-icon-state-edit {
  color: rgb(var(--secondary-color)) !important;
}

.header-step-out .mat-step-header .mat-step-label {
  display: none !important;
}

.header-step-out .mat-step-header .mat-step-label.mat-step-label-selected {
  display: flex !important;
}

.mat-menu-panel {
  max-width: 400px !important;
}

.mkt-dialoguing .ui-carousel-item {
  max-width: 50% !important;
}

.fc-mobile-view {
  /*bottom: calc(15px + 2.6em) !important;*/
  height: calc(100vh - 5.2em - 0.6vh) !important;
}

.notis0 .mat-horizontal-content-container {
  padding: 0 calc(1.2vw + 0.2vh) calc(1.2vw + 0.2vh) calc(1.2vw + 0.2vh) !important;
}

@media (max-width: 960px) {
  .cdk-overlay-pane {
    max-width: 100vw !important;
  }
}

.flatpickr-calendar.open {
  z-index: 9999999999 !important;
}

mat-dialog-container .mat-tab-header {
  position: relative !important;
}

mat-dialog-container .mat-tab-body-wrapper {
  margin-top: 0 !important;
  /*width: 70vw;
  height: 70vh;*/
}

/*mat-dialog-container .mat-tab-body-wrapper .mat-tab-group .mat-tab-body-wrapper {
  width: 100%;
  height: auto;
}*/

.element-detail {
  max-height: 60vh;
  overflow: auto !important;
}

.leads-table .taulesgrans tbody tr:hover {
  background: #fff !important;
}

.leads-table .taulesgrans tbody tr:hover td {
  color: #000 !important;
}

.leads-table .taulesgrans tbody tr .cdk-column-expandedDetail {
  background-color: rgba(0, 0, 0, 0.075) !important;
}

.config-leads-faqs .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.5em;
}

mat-expansion-panel-header {
  font-weight: 600 !important;
}

mat-expansion-panel-header mat-panel-title {
  color: #000 !important;
}

mat-expansion-panel-header mat-panel-description {
  color:rgb( var(--tertiary-color)) !important;
}

.dialog-footer-2 button,
.dialog-footer button,
.dialog-footer-1 button,
.dialog-footer-3 button,
.dialog-footer-4 button {
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.mat-dialog-content {
  font-weight: 600 !important;
}

.mat-expansion-panel-header-description,
.mat-expansion-panel-header-title {
  width: 0;
}

.mat-calendar-body-cell-content {
  font-weight: 500;
}

.mat-calendar-body-selected {
  box-shadow: inherit !important;
  border-radius: 0.2rem;
  border: inherit !important;
}

app-inmo-portal-dialog .dialog-footer-2 {
  min-width: 40vw;
}

.imgList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.imgList li {
  float: left;
}

.imgList li img {
  cursor: default;
  padding: 1rem;
}

.mat-form-field {
  width: 100%;
}

.aaadaadasdasd .mat-dialog-container {
  border: 3px solid blue;
}

.all-chat
  .chat-footer
  .mat-form-field-appearance-outline
  .mat-form-field-outline-end,
.all-chat
  .search-header
  .mat-form-field-appearance-outline
  .mat-form-field-outline-end {
  border-style: solid;
  border-radius: 1.5em !important;
}

.all-chat
  .chat-footer
  .mat-form-field-appearance-outline
  .mat-form-field-outline-start,
.all-chat
  .search-header
  .mat-form-field-appearance-outline
  .mat-form-field-outline-start {
  display: none;
}

.stepper-config-portals .mat-horizontal-stepper-header-container {
  display: none !important;
}

#containerbuscar mat-tab-header .mat-tab-label-active {
  color: rgb(var(--secondary-color)) !important;
}

#containerbuscar .mat-tab-group.mat-primary .mat-ink-bar,
#containerbuscar .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: rgb(var(--secondary-color)) !important;
}

.btns-filter {
  color: rgb(var(--primary-color)) !important;
  border: 1px solid rgb(var(--primary-color)) !important;
  background: #fff !important;
  border-radius: 0.1rem !important;
  line-height: 25px !important;
  padding: 0 10px !important;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
}

.btns-filter {
  width: auto;
}

.btns-filter .material-icons {
  font-size: 15px;
  vertical-align: sub;
}

.btns-filter:hover {
  color: #fff !important;
  background: rgb(var(--primary-color)) !important;
}

.block-btns-filter {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 0;
  position: relative;
  width: 100%;
}

/********************************************************* GENERIC CSS *********************************************************/

/********************************************************* GENERIC CSS *********************************************************/

/********************************************************* GENERIC CSS *********************************************************/
.header-generic {
  width: 100%;
  height: 2.6em;
  top: 0;
  position: fixed;
  text-align: center;
  z-index: 99999;
  background: #1c1c1c;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
  padding: 0.2vw 1.2vw;
}

.footer-generic {
  width: 100%;
  height: 2.6em;
  text-align: center;
  z-index: 99999;
  background: #3c3c3c;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
  padding: 0.2vw 1.2vw;
}

.delete-icon-generic .generic-icon-delete,
.delete-icon-generic .mat-expansion-panel-header-title i {
  color:rgb( var(--tertiary-color));
}

.generic-buttons-blue button,
.creating-mode-buttons .generic-buttons-blue button {
  color: rgb(var(--primary-color)) !important;
  border: 1px solid rgb(var(--primary-color)) !important;
  background: #fff !important;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
}

.generic-buttons-blue button .material-icons,
.creating-mode-buttons .generic-buttons-blue button .material-icons {
  color: rgb(var(--primary-color)) !important;
}

.generic-buttons-blue button circle,
.creating-mode-buttons .generic-buttons-blue button circle {
  stroke: rgb(var(--primary-color)) !important;
}

.generic-buttons-blue:hover button,
.creating-mode-buttons .generic-buttons-blue:hover button {
  color: #fff !important;
  background: rgb(var(--primary-color)) !important;
  border: 1px solid rgb(var(--primary-color)) !important;
}

.generic-buttons-blue:hover button .material-icons,
.creating-mode-buttons .generic-buttons-blue:hover button .material-icons {
  color: #fff !important;
}

.generic-buttons-blue:hover button circle,
.creating-mode-buttons .generic-buttons-blue:hover button circle {
  stroke: #fff !important;
}

.generic-buttons-red button,
.creating-mode-buttons .generic-buttons-red button {
  color:rgb( var(--tertiary-color)) !important;
  border: 1px solidrgb( var(--tertiary-color)) !important;
  background: #fff !important;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
}

.generic-buttons-red button .material-icons,
.creating-mode-buttons .generic-buttons-red button .material-icons {
  color:rgb( var(--tertiary-color)) !important;
}

.generic-buttons-red button circle,
.creating-mode-buttons .generic-buttons-red button circle {
  stroke:rgb( var(--tertiary-color)) !important;
}

.generic-buttons-red:hover button,
.creating-mode-buttons .generic-buttons-red:hover button {
  color: #fff !important;
  background:rgb( var(--tertiary-color)) !important;
  border: 1px solidrgb( var(--tertiary-color)) !important;
}

.generic-buttons-red:hover button .material-icons,
.creating-mode-buttons .generic-buttons-red:hover button .material-icons {
  color: #fff !important;
}

.generic-buttons-red:hover button circle,
.creating-mode-buttons .generic-buttons-red:hover button circle {
  stroke: #fff !important;
}

.generic-buttons-yellow button,
.creating-mode-buttons .generic-buttons-yellow button {
  color: rgb(var(--secondary-color)) !important;
  border: 1px solid #1c1c1c !important;
  background: #1c1c1c !important;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
}

.generic-buttons-yellow button .material-icons,
.creating-mode-buttons .generic-buttons-yellow button .material-icons {
  color: rgb(var(--secondary-color)) !important;
}

.generic-buttons-yellow button circle,
.creating-mode-buttons .generic-buttons-yellow button circle {
  stroke: rgb(var(--secondary-color)) !important;
}

.generic-buttons-yellow:hover button,
.creating-mode-buttons .generic-buttons-yellow:hover button {
  color: #000 !important;
  background: rgb(var(--secondary-color)) !important;
  border: 1px solid rgb(var(--secondary-color)) !important;
}

.generic-buttons-yellow:hover button .material-icons,
.creating-mode-buttons .generic-buttons-yellow:hover button .material-icons {
  color: #000 !important;
}

.generic-buttons-yellow:hover button circle,
.creating-mode-buttons .generic-buttons-yellow:hover button circle {
  stroke: #000 !important;
}

.generic-buttons-black button,
.creating-mode-buttons .generic-buttons-black button {
  color: #000 !important;
  border: 1px solid #000 !important;
  background: #fff !important;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
}

.generic-buttons-black button, .generic-buttons-yellow button, .generic-buttons-blue button, .generic-buttons-red button,
.generic-buttons-black-revert button, .generic-buttons-yellow-revert button, .generic-buttons-blue-revert button, .generic-buttons-red-revert button {
  border-radius: 13px!important;
  line-height: 25px!important;
  padding: 2px 15px!important;
  transition: 0.4s;
}

.generic-buttons-black button .material-icons,
.creating-mode-buttons .generic-buttons-black button .material-icons {
  color: #000 !important;
}

.generic-buttons-black button circle,
.creating-mode-buttons .generic-buttons-black button circle {
  stroke: #000 !important;
}

.generic-buttons-black:hover button,
.creating-mode-buttons .generic-buttons-black:hover button {
  color: #fff !important;
  background: #1c1c1c !important;
  border: 1px solid #1c1c1c !important;
}

.generic-buttons-black:hover button .material-icons,
.creating-mode-buttons .generic-buttons-black:hover button .material-icons {
  color: #fff !important;
}

.generic-buttons-black:hover button circle,
.creating-mode-buttons .generic-buttons-black:hover button circle {
  stroke: #fff !important;
}

.generic-buttons-blue-revert button,
.creating-mode-buttons .generic-buttons-blue-revert button {
  color: #fff !important;
  background: rgb(var(--primary-color)) !important;
  border: 1px solid rgb(var(--primary-color)) !important;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
}

.generic-buttons-blue-revert button .material-icons,
.creating-mode-buttons .generic-buttons-blue-revert button .material-icons {
  color: #fff !important;
}

.generic-buttons-blue-revert button circle,
.creating-mode-buttons .generic-buttons-blue-revert button circle {
  stroke: #fff !important;
}

.generic-buttons-blue-revert:hover button,
.creating-mode-buttons .generic-buttons-blue-revert:hover button {
  color: rgb(var(--primary-color)) !important;
  background: #fff !important;
  border: 1px solid rgb(var(--primary-color)) !important;
}

.generic-buttons-blue-revert:hover button .material-icons,
.creating-mode-buttons
  .generic-buttons-blue-revert:hover
  button
  .material-icons {
  color: rgb(var(--primary-color)) !important;
}

.generic-buttons-blue-revert:hover button circle,
.creating-mode-buttons .generic-buttons-blue-revert:hover button circle {
  stroke: rgb(var(--primary-color)) !important;
}

.generic-buttons-red-revert button,
.creating-mode-buttons .generic-buttons-red-revert button {
  color: #fff !important;
  background:rgb( var(--tertiary-color)) !important;
  border: 1px solidrgb( var(--tertiary-color)) !important;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
}

.generic-buttons-red-revert button .material-icons,
.creating-mode-buttons .generic-buttons-red-revert button .material-icons {
  color: #fff !important;
}

.generic-buttons-red-revert button circle,
.creating-mode-buttons .generic-buttons-red-revert button circle {
  stroke: #fff !important;
}

.generic-buttons-red-revert:hover button,
.creating-mode-buttons .generic-buttons-red-revert:hover button {
  color:rgb( var(--tertiary-color)) !important;
  background: #fff !important;
  border: 1px solidrgb( var(--tertiary-color)) !important;
}

.generic-buttons-red-revert:hover button .material-icons,
.creating-mode-buttons
  .generic-buttons-red-revert:hover
  button
  .material-icons {
  color:rgb( var(--tertiary-color)) !important;
}

.generic-buttons-red-revert:hover button circle,
.creating-mode-buttons .generic-buttons-red-revert:hover button circle {
  stroke:rgb( var(--tertiary-color)) !important;
}

.generic-buttons-yellow-revert button,
.creating-mode-buttons .generic-buttons-yellow-revert button {
  color: #000 !important;
  border: 1px solid rgb(var(--secondary-color)) !important;
  background: rgb(var(--secondary-color)) !important;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
}

.generic-buttons-yellow-revert button .material-icons,
.creating-mode-buttons .generic-buttons-yellow-revert button .material-icons {
  color: #000 !important;
}

.generic-buttons-yellow-revert button circle,
.creating-mode-buttons .generic-buttons-yellow-revert button circle {
  stroke: #000 !important;
}

.generic-buttons-yellow-revert:hover button,
.creating-mode-buttons .generic-buttons-yellow-revert:hover button {
  color: rgb(var(--secondary-color)) !important;
  border: 1px solid #1c1c1c !important;
  background: #1c1c1c !important;
}

.generic-buttons-yellow-revert:hover button .material-icons,
.creating-mode-buttons
  .generic-buttons-yellow-revert:hover
  button
  .material-icons {
  color: rgb(var(--secondary-color)) !important;
}

.generic-buttons-yellow-revert:hover button circle,
.creating-mode-buttons .generic-buttons-yellow-revert:hover button circle {
  stroke: rgb(var(--secondary-color)) !important;
}

.generic-buttons-black-revert button,
.creating-mode-buttons .generic-buttons-black-revert button {
  color: #fff !important;
  background: #000 !important;
  border: 1px solid #000 !important;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
}

.generic-buttons-black-revert button circle,
.creating-mode-buttons .generic-buttons-black-revert button circle {
  stroke: #fff !important;
}

.generic-buttons-black-revert button .material-icons,
.creating-mode-buttons .generic-buttons-black-revert button .material-icons {
  color: #fff !important;
}

.generic-buttons-black-revert:hover button,
.creating-mode-buttons .generic-buttons-black-revert:hover button {
  color: #000 !important;
  border: 1px solid #000 !important;
  background: #fff !important;
}

.generic-buttons-black-revert:hover button .material-icons,
.creating-mode-buttons
  .generic-buttons-black-revert:hover
  button
  .material-icons {
  color: #000 !important;
}

.generic-buttons-black-revert:hover button circle,
.creating-mode-buttons .generic-buttons-black-revert:hover button circle {
  stroke: #000 !important;
}

.generic-buttons-blue button .material-icons,
.generic-buttons-yellow button .material-icons,
.generic-buttons-red button .material-icons,
.generic-buttons-black button .material-icons,
.generic-buttons-blue-revert button .material-icons,
.generic-buttons-yellow-revert button .material-icons,
.generic-buttons-red-revert button .material-icons,
.generic-buttons-black-revert button .material-icons {
  font-size: 15px;
  /*vertical-align: sub;*/
  /*vertical-align: -webkit-baseline-middle;*/
  vertical-align: top !important;
  line-height: inherit;
}

.full-width-button button {
  width: 100%;
}

.guias-h1 {
  padding: 1em 1.5em;
  background: #1b1b1b;
  border-left: 1px solid #6a6a6a;
}

.guias-h1 h1 {
  font-size: 14px;
  font-weight: 800;
  margin: 0;
  letter-spacing: 1px;
  color: rgb(var(--secondary-color));
}

.generic-tab-group-guias .mat-tab-header {
  position: relative !important;
  background-color: rgba(var(--secondary-color), 0.3);
}

.generic-tab-group-guias .mat-tab-body-wrapper {
  margin: 0;
}

.generic-tab-group-guias .mat-tab-label-container {
  padding-left: 0em;
}

.generic-tab-group-guias .mat-tab-label-active {
  color: #000 !important;
  background: rgb(var(--secondary-color)) !important;
}

.generic-tab-group-guias .mat-tab-group.mat-primary .mat-ink-bar,
.generic-tab-group-guias .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #1c1c1c !important;
}

.generic-tab-group-guias h2 {
  font-weight: 700;
  font-size: 19px;
  margin-top: 2em;
  margin-bottom: 0.5em;
  color: rgb(var(--secondary-color));
  background: #1c1c1c;
  padding: 9px 12px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.generic-tab-group-guias h3 {
  font-weight: 700;
  font-size: 17px;
  margin-top: 1em;
  margin-bottom: 0.2em;
  text-decoration: underline;
}

.generic-tab-group-guias h4 {
  font-weight: 800;
  font-size: 14px;
}

.generic-tab-group-guias .box {
  /*height: calc(100vh - 5.2em - 3em - 4em - 3.5px);*/
  height: calc(100vh - 5.2em - 4.8em - 0.6vh - 15px);
  overflow: auto;
  font-size: 14px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  padding: 0 3vw;
}

.generic-tab-group-guias .box li {
  font-weight: 400;
}

.generic-tab-group-guias .generic-type-10 .mat-expansion-panel-header-title {
  font-weight: 700;
  font-size: 14px;
  color: rgb(var(--secondary-color)) !important;
}

.generic-tab-group-guias
  .generic-type-10
  .generic-type-10
  .mat-expansion-panel-header-title {
  font-size: 12px;
}

.generic-tab-group-guias .mat-expansion-panel-header {
  background: #222;
  border-radius: 0 !important;
}

.generic-tab-group-guias .mat-expansion-panel {
  box-shadow: 0 3px 1px -2px #222, 0 2px 2px 0 #222,
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.generic-tab-group-guias .mat-expansion-panel:hover {
  box-shadow: 0 3px 1px -2px #1c1c1c, 0 2px 2px 0 #1c1c1c, 0 1px 5px 0 #1c1c1c;
}

.generic-tab-group-guias .mat-expansion-panel:hover .mat-expansion-panel-header,
.generic-tab-group-guias .mat-expansion-panel-header.mat-expanded:focus,
.generic-tab-group-guias .mat-expansion-panel-header.mat-expanded:hover {
  background: #1c1c1c !important;
}

.generic-tab-group-guias .mat-expansion-panel-header-description,
.generic-tab-group-guias .mat-expansion-indicator::after {
  color: #fff !important;
}

.generic-tab-group-guias img {
  border-radius: 3px;
}

.generic-tab-group-guias
  .mat-expansion-panel:hover
  .mat-expansion-indicator::after {
  color: rgb(var(--secondary-color)) !important;
}

.generic-tab-group-guias .mat-expansion-panel-body {
  padding: 16px 24px;
}

.generic-tab-group-guias .mat-vertical-stepper-header {
  background-color: #222;
  padding: 12px;
}

.generic-tab-group-guias .mat-vertical-stepper-header .mat-step-icon {
  background: rgb(var(--secondary-color)) !important;
  font-weight: 700;
  color: #000 !important;
}

.generic-tab-group-guias .mat-vertical-stepper-header .mat-step-label {
  color: rgb(var(--secondary-color));
  font-weight: 700;
  font-size: 17px;
}

.generic-tab-group-guias .mat-vertical-content-container {
  margin: 10px 0 !important;
}

.generic-tab-group-guias .generic-type-102 {
  border: 1px solid #2222223b;
  min-height: 400px;
}

.h1-top-guias h1 {
  font-size: 18px;
}

.box-pem-tittle h2 {
  margin-top: 0 !important;
}

.box-pem-tittle  {
  padding-right: 1em;
}

@media (max-width: 960px) {
  .box-pem-tittle  {
    padding-right: 0em !important;
  }
}

.button-header {
  width: auto !important;
  max-width: inherit !important;
  flex-grow: initial !important;
  flex-basis: auto !important;
}

.session-active .lista-guias  {
  height: calc(100vh - 5.2em) !important;
}

.session-no-active .lista-guias  {
  height: calc(100vh - 2.6em) !important;
}

.session-active .generic-tab-group-guias .box {
  height: calc(100vh - 13em) !important;
}

.staticHTML .generic-tab-group-guias .box {
  height: calc(100vh - 6.9em) !important;
}

.staticHTML .lista-guias {
  height: 100vh !important;
}

.button-header button {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  height: 30px;
  color: #fff;
  line-height: 30px;
  cursor: pointer;
  margin: 0 12px !important;
  border-radius: 0;
}

.button-header button:hover {
  border-bottom: 2px solid rgb(var(--secondary-color)) !important;
}

.header-generic .logo img {
  max-width: 135px;
  float: left;
  height: 1.48em;
  margin-top: 0.56em;
  width: auto;
}

.header-generic .logo-gt360 img {
  max-width: 135px;
  float: left;
  height: 2em;
  margin-top: 0.3em;
  width: auto;
}

.footer-generic .logo img {
  max-width: 135px;
  /*float: right;*/
  height: 1.48em;
  margin-top: 0.56em;
  width: auto;
}

.footer-generic .logo-gt360 img {
  max-width: 135px;
  /*float: right;*/
  height: 2em;
  margin-top: 0.3em;
  width: auto;
}

.button-no-flex,
.custom-button-icons-header {
  width: auto !important;
  max-width: inherit !important;
  flex-grow: initial !important;
  flex-basis: auto !important;
}

.float-top-right {
  position: fixed;
  right: 40px;
  top: calc(8px + 28px);
  z-index: 999;
}

.mat-select-disabled .mat-form-field-flex,
.mat-select-disabled .mat-select-trigger {
  cursor: not-allowed;
}

.value-filter .mat-form-field-outline-thick {
  opacity: 1 !important;
  background: rgba(var(--tertiary-color), 0.3);
}

.button-generic .mat-badge-content {
  border: 2px solid #000000 !important;
  color: #000000 !important;
  background: #ffffff !important;
  font-size: 12px !important;
  top: 0px !important;
  right: -10px !important;
  font-weight: bold !important;
  height: 20px !important;
  line-height: 18px !important;
  max-width: 30px !important;
  min-width: 20px !important;
  width: auto !important;
  border-radius: 10px;
}

.yellow-badge .button-generic .mat-badge-content {
  border: 2px solid #000000 !important;
  color: #000000 !important;
  background: rgb(var(--secondary-color)) !important;
  font-size: 12px !important;
  top: 0px !important;
  right: -10px !important;
  font-weight: bold !important;
  height: 20px !important;
  line-height: 18px !important;
  max-width: 30px !important;
  min-width: 20px !important;
  width: auto !important;
  border-radius: 10px;
}

.red-badge .button-generic .mat-badge-content {
  border: 2px solid #000000 !important;
  color: #000000 !important;
  background:rgb( var(--tertiary-color)) !important;
  font-size: 12px !important;
  top: 0px !important;
  right: -10px !important;
  font-weight: bold !important;
  height: 20px !important;
  line-height: 18px !important;
  max-width: 30px !important;
  min-width: 20px !important;
  width: auto !important;
  border-radius: 10px;
}

.black-badge .button-generic .mat-badge-content {
  border: 2px solid #fff !important;
  color: #fff !important;
  background: #000 !important;
  font-size: 12px !important;
  top: 0px !important;
  right: -10px !important;
  font-weight: bold !important;
  height: 20px !important;
  line-height: 18px !important;
  max-width: 30px !important;
  min-width: 20px !important;
  width: auto !important;
  border-radius: 10px;
}

.blue-badge .button-generic .mat-badge-content {
  border: 2px solid #fff !important;
  color: #fff !important;
  background: rgb(var(--primary-color)) !important;
  font-size: 12px !important;
  top: 0px !important;
  right: -10px !important;
  font-weight: bold !important;
  height: 20px !important;
  line-height: 18px !important;
  max-width: 30px !important;
  min-width: 20px !important;
  width: auto !important;
  border-radius: 10px;
}

.white-badge .button-generic .mat-badge-content {
  border: 2px solid #000000 !important;
  color: #000000 !important;
  background: #fff !important;
  font-size: 12px !important;
  top: 0px !important;
  right: -10px !important;
  font-weight: bold !important;
  height: 20px !important;
  line-height: 18px !important;
  max-width: 30px !important;
  min-width: 20px !important;
  width: auto !important;
  border-radius: 10px;
}

.move-top-badge .button-generic .mat-badge-content {
  top: -7px !important;
}

.icons-header-custom .mat-badge-content {
  border: 2px solid #000000 !important;
  color: #000000 !important;
  background: rgb(var(--secondary-color)) !important;
  font-size: 12px !important;
  top: 0 !important;
  right: -10px !important;
  font-weight: bold !important;
  height: 20px !important;
  line-height: 18px !important;
  max-width: 30px !important;
  min-width: 20px !important;
  width: auto !important;
  border-radius: 10px;
}

.profile-icon img {
  height: 1.8em !important;
  width: 1.8em !important;
  border-radius: 100%;
  border: 1px solid rgb(var(--secondary-color));
  object-fit: cover;
  background: #fff;
}

.profile-icon button {
  margin: 0 !important;
}

.mat-paginator-page-size-label  {
  display: none !important;
  opacity: 0;
  height: 0;
  width: 0;
  visibility: hidden;
}

.bottom-table-generic-mb .mat-paginator-range-actions {
  width: 75%;
  justify-content: flex-end;
}

.bottom-table-generic-mb .mat-paginator-page-size {
  width: calc(25% - 16px);
  margin-left: 8px;
}

.bottom-table-generic-mb .mat-paginator-range-label {
  margin: 0 5px;
}

.fix-class-slide-toggle .mat-slide-toggle-label {
  align-items: baseline !important;
}

.fix-class-slide-toggle .mat-slide-toggle-bar {
  width: 30px !important;
  height: 10px !important;
}

.fix-class-slide-toggle .mat-slide-toggle-thumb-container {
  top: 0 !important;
}

.fix-class-slide-toggle .mat-slide-toggle-thumb {
  height: 10px !important;
  width: 10px !important;
}

.fix-class-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(19px, 0, 0) !important;
}

.fix-class-slide-toggle .mat-slide-toggle-ripple {
  top: calc(50% - 13px) !important;
  left: calc(50% - 13px) !important;
  height: 16px !important;
  width: 16px !important;
  border-radius: 50%;
}

.fix-class-slide-toggle .mat-slide-toggle-content i {
  vertical-align: sub;
  font-size: 18px;
}

app-canva .canva .QnQnDA {
  display: none;
}

app-canva .canva {
  height: 100% !important;
  min-height: 100vh;
}

.second-bar-tools.icons-header-custom .mat-badge-content {
  top: -5px !important;
  right: 0px !important;
}

.agenda-container .second-bar-tools.icons-header-custom .mat-badge-content {
  top: -8px !important;
  right: -2px !important;
}

.mat-form-field-appearance-standard.generic-input-disabled .mat-form-field-underline, .mat-form-field-appearance-legacy.generic-input-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0, rgba(0, 0, 0, 0.42) 33%, transparent 0);
  background-size: 4px 1px;
  background-repeat: repeat-x;
  background-position: 0;
  background-color: transparent;
}

.mat-form-field-appearance-fill.generic-input-disabled
  .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline.generic-input-disabled
  .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.generic-input-disabled
  .mat-form-field-infix::after,
.generic-input-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}

.generic-input-disabled input,
.generic-input-disabled textarea {
  cursor: not-allowed;
}

.generic-img-contain img {
  width: 100%;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain !important;
}

.generic-img-contain app-text {
  height: 100%;
  display: contents !important;
}

.generic-img-contain {
  display: flex;
}

.generic-img-contain-align-left img {
  object-position: left;
}

.generic-img-contain-align-right img {
  object-position: right;
}


html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.component-class .mat-form-field-prefix,
.component-class .mat-form-field-suffix {
  display: flex !important;
}

/*.cdk-global-overlay-wrapper {
  pointer-events: auto !important;
  background: #00000075 !important;
}*/

.dialog-material-generic-no-close-out {
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  padding: 2vh 2vw !important;
  background: #00000075 !important;
  align-items: center;
  justify-content: center;
}

.dialog-material-generic-no-close-out .mat-dialog-container {
  width: auto !important;
  height: auto !important;
}

.dialog-material-generic .mat-dialog-actions {
  justify-content: flex-end;
  min-width: 40vw;
  padding: 20px 0;
}

.dialog-material-generic .mat-dialog-actions .generic-type-5 , .dialog-material-generic .mat-dialog-actions .generic-type-11 , .dialog-material-generic .mat-dialog-actions .generic-type-91 {
  max-width: 100% !important;
  flex-grow: 0 !important;
  flex-basis: auto !important;
}

.dialog-material-generic .mat-dialog-actions .generic-type-11 mat-form-field {
  margin: -10px 0 0 0 !important;
}

.dialog-material-generic .mat-dialog-actions .generic-type-5.generic-order-2,
.dialog-material-generic .mat-dialog-actions .generic-type-5.generic-order-3,
.dialog-material-generic .mat-dialog-actions .generic-type-5.generic-order-4,
.dialog-material-generic .mat-dialog-actions .generic-type-5.generic-order-5,
.dialog-material-generic .mat-dialog-actions .generic-type-5.generic-order-6 {
  margin-left: 20px;
}

.stepper-header-hidden mat-step-header,
.stepper-header-hidden .mat-stepper-vertical-line::before,
.stepper-header-hidden .mat-stepper-horizontal-line {
  display: none !important;
}

.stepper-header-hidden .mat-vertical-content-container {
  /* MIRAR PORQUE NO LO COJE */
  margin: 0 !important;
}

.dialog-material-generic .stepper-header-hidden .mat-vertical-content {
  /* MIRAR PORQUE NO LO COJE */
  padding: 0 0px 24px 0px !important;
}

.dialog-material-generic .stepper-header-hidden mat-step-header {
  padding: 24px 0;
}

/*.generic-type-8 .mat-tab-header {
  position: relative !important;
  height: auto;
}

.generic-type-8 .mat-tab-body-wrapper {
  margin-top: auto;
}*/

/*.generic-tab-group-guias .mat-tab-group.mat-primary .mat-ink-bar, .generic-tab-group-guias .mat-tab-nav-bar.mat-primary .mat-ink-bar{
  background: #fff !important;
  height: 5px;
  border: 1px solid #000;
  border-radius: 10px;
}*/

.hidden {
  display: none !important;
}

.generic-type-3 th,
.generic-type-3 td {
  text-align: left;
  padding: 0 8px 1px 8px !important;
}

.generic-type-3 thead tr {
  line-height: 2em;
  color: #fff;
  background: rgb(var(--primary-color));
}

.generic-type-3 th {
  color: #fff;
  line-height: 1.5em;
  font-size: 12px;
  font-weight: 500;
}

.generic-type-3 .mat-sort-header-arrow {
  color: rgb(var(--secondary-color));
}

.generic-type-3 .buttons-head {
  display: flex;
  animation: pampalluguetes 1s linear;
  animation-fill-mode: both;
  animation-iteration-count: 8;
}

.generic-type-3 .buttons-head button {
  width: 50%;
  margin: 0;
  background-color: inherit;
  color: #fff;
  border: 0;
  padding: 0;
}

.generic-type-3 table {
  border-left: 1px solid rgba(var(--primary-color), 0.9);
  border-right: 1px solid rgba(var(--primary-color), 0.9);
}

.generic-type-3 tbody tr {
  line-height: 2em;
  height: auto;
  padding: 0;
  /*border-bottom: 1px solid rgba(0, 0, 0, 0.05);*/
  background-color: rgba(0, 0, 0, 0.025);
  animation-delay: 0s;
}

.generic-type-3:not(.is-table-with-expansion) tbody tr:nth-child(2n + 1) , .generic-type-3:has(.mat-column-expandedDetail) tbody tr:nth-child(4n - 1) {
  background-color: rgba(0, 0, 0, 0);
  animation-delay: 0s !important;
}

.generic-type-3 tbody tr:hover {
  //background-color: rgba(var(--primary-color), 0.75) !important;
  background-color: rgba(var(--primary-color), 0.2) !important;
}

.generic-type-3 tbody td {
  /*color: rgba(51,51,51,0.44);*/
  vertical-align: initial;
  font-size: 12px;
  font-weight: 400;
}

.generic-type-3 tbody tr:hover td {
  //color: #fff !important;
  transition: all 0.08s ease-in-out;
}

.generic-type-3 .buttons-body {
  display: flex;
}

.generic-type-3 i {
  font-size: 18px !important;
  padding: 0 5px;
}

.generic-type-3 th button,
.generic-type-3 button.no-angular-class {
  width: 100%;
}

.generic-type-3 th i {
  font-weight: bold;
  color: rgb(var(--secondary-color));
  text-align: center;
}

.generic-type-3 .buttons-body button {
  margin: 0;
  padding-right: 5% !important;
}

.generic-type-3 tr.mat-header-row {
  height: auto !important;
}

.generic-type-3 .delete-icon-generic {
  width: 100%;
}

.generic-type-3 .visibility-icon-generic {
  color: rgb(var(--primary-color));
}

.generic-type-3 .edit-icon-generic {
  color: #000;
}

.generic-type-3 .save_alt-icon-generic {
  color: green;
}

.generic-type-3 .save_alt-icon-generic button i {
  font-weight: bold;
}

.generic-type-104.no-icon-generic,
.generic-type-103.no-icon-generic {
  font-size: 15px;
}

.mat-tab-label-active mat-icon {
  font-weight: bold;
}

.generic-type-3 .mat-paginator-range-actions button:disabled {
  display: none;
}

.generic-type-0 .generic-type-85,
.generic-type-0 .generic-type-92,
.generic-type-0 .generic-type-98,
.generic-type-0 .generic-type-158,
.generic-type-0 .generic-type-159 {
  display: none;
}

.generic-type-0 app-generic-creator-custom .generic-type-85,
.generic-type-0 app-generic-creator-custom .generic-type-92,
.generic-type-0 app-generic-creator-custom .generic-type-98,
.generic-type-0 app-generic-creator-custom .generic-type-158,
.generic-type-0 app-generic-creator-custom .generic-type-159 {
  display: initial;
}

.generic-type-10.generic-buttons-red {
  margin-bottom: 1em;
}

.generic-type-5.generic-buttons-red .mat-button-wrapper,
.generic-type-5.generic-buttons-red-revert .mat-button-wrapper,
.generic-type-5.generic-buttons-yellow .mat-button-wrapper,
.generic-type-5.generic-buttons-yellow-revert .mat-button-wrapper,
.generic-type-5.generic-buttons-blue .mat-button-wrapper,
.generic-type-5.generic-buttons-blue-revert .mat-button-wrapper,
.generic-type-5.generic-buttons-black .mat-button-wrapper,
.generic-type-5.generic-buttons-black-revert .mat-button-wrapper {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.generic-type-5.generic-buttons-red .mat-button-wrapper i,
.generic-type-5.generic-buttons-red-revert .mat-button-wrapper i,
.generic-type-5.generic-buttons-yellow .mat-button-wrapper i,
.generic-type-5.generic-buttons-yellow-revert .mat-button-wrapper i,
.generic-type-5.generic-buttons-blue .mat-button-wrapper i,
.generic-type-5.generic-buttons-blue-revert .mat-button-wrapper i,
.generic-type-5.generic-buttons-black .mat-button-wrapper i,
.generic-type-5.generic-buttons-black-revert .mat-button-wrapper i {
  margin-right: 5px !important;
}

.yes-no-radio .radio-orden-0 .mat-radio-outer-circle,
.yes-no-radio .radio-orden-0 .mat-radio-inner-circle {
  border-color: #17c33e !important;
}

.yes-no-radio .radio-orden-0.mat-radio-checked .mat-radio-outer-circle,
.yes-no-radio .radio-orden-0 .mat-radio-inner-circle {
  border-color: #b3b3b3 !important;
  background: #17c33e !important;
}

.yes-no-radio .radio-orden-1 .mat-radio-outer-circle,
.yes-no-radio .radio-orden-1 .mat-radio-inner-circle {
  border-color: #dc3544 !important;
}

.yes-no-radio .radio-orden-1.mat-radio-checked .mat-radio-outer-circle,
.yes-no-radio .radio-orden-1 .mat-radio-inner-circle {
  border-color: #b3b3b3 !important;
  background: #dc3544 !important;
}

.yes-no-radio .mat-radio-button,
.yes-no-radio .mat-radio-group {
  width: 100%;
  display: flex;
}

.yes-no-radio .generic-type-101 p {
  text-align: center;
  font-weight: 500;
  margin-top: 2em;
}

.generic-tab-header-fixed .mat-tab-header {
  position: relative !important;
}

.generic-tab-header-fixed .mat-tab-body-wrapper {
  margin: 0 !important;
}

.generic-tab-full-width .mat-tab-labels ,
.generic-tab-full-width-2 .mat-tab-labels , .generic-tab-full-width-3 .mat-tab-labels , .generic-tab-full-width-4 .mat-tab-labels , .generic-tab-full-width-5 .mat-tab-labels {
  justify-content: space-around !important;
  flex-wrap: nowrap !important;
}

.generic-tab-full-width .mat-tab-labels .mat-tab-label ,
.generic-tab-full-width-2 .mat-tab-labels .mat-tab-label , .generic-tab-full-width-3 .mat-tab-labels .mat-tab-label , .generic-tab-full-width-4 .mat-tab-labels .mat-tab-label , .generic-tab-full-width-5 .mat-tab-labels .mat-tab-label {
  width: 100% !important;
}

.generic-type-12 .mat-radio-label-content {
  padding-right: 8px !important;
  padding-left: 3px !important;
}

.generic-type-15 .mat-checkbox-inner-container {
  margin-top: 6px !important;
}

.home-first-part h1 {
  font-size: 78px;
  font-weight: bold;
  letter-spacing: -5px;
  color: #000;
}

.home-first-part {
  padding: 0 !important;
}

.weight100.generic-type-101 .component-class > * {
  font-weight: 100 !important;
}

.weight200.generic-type-101 .component-class > * {
  font-weight: 200 !important;
}

.wheight300.generic-type-101 .component-class > * {
  font-weight: 300 !important;
}

.weight400.generic-type-101 .component-class > * {
  font-weight: 400 !important;
}

.weight500.generic-type-101 .component-class > * {
  font-weight: 500 !important;
}

.weight600.generic-type-101 .component-class > * {
  font-weight: 600 !important;
}

.weight700.generic-type-101 .component-class > * {
  font-weight: 700 !important;
}

.weight800.generic-type-101 .component-class > * {
  font-weight: 800 !important;
}

.weight900.generic-type-101 .component-class > * {
  font-weight: 900 !important;
}

.weightBold.generic-type-101 .component-class > * {
  font-weight: bold !important;
}

.textAlignCenter.generic-type-101 .component-class > *,
.textAlignCenter {
  text-align: center !important;
}

.textAlignLeft.generic-type-101 .component-class > *,
.textAlignLeft {
  text-align: left !important;
}

.textAlignRight.generic-type-101 .component-class > *,
.textAlignRight {
  text-align: right !important;
}

.textAlignJustify.generic-type-101 .component-class > *,
.textAlignJustify {
  text-align: justify !important;
}

.fontFamilyMarketDeco.generic-type-101 .component-class > * {
  font-family: "MarketDeco", sans-serif !important;
}

.fontFamilyInherit.generic-type-101 .component-class > *,
.fontFamilyInherit {
  font-family: inherit !important;
}

.lineDecorationRed.generic-type-101 .component-class > * {
  text-decoration: line-through;
  text-decoration-thickness: 9px;
  text-decoration-color: rgb(var(--tertiary-color))d4;
}

.fullCoverImg.generic-type-101 .component-class > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.generic-buttons-big button {
  font-size: 15px !important;
  line-height: 33px !important;
}

.generic-buttons-extrabig button {
  font-size: 20px !important;
  line-height: 38px !important;
}

.fullHeightSectionNoActiveSesion1 {
  min-height: calc(100vh - 2.6em - 0.6vh - 15px) !important;
}

.fullHeightSectionNoActiveSesion2 {
  min-height: calc(100vh - 7.2em - 0.6vh - 15px) !important;
}

.backgroundImageSection {
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat;
}

.backgroundImageSectionNoFixed {
  background-size: cover !important;
  background-attachment: initial !important;
  background-position: center !important;
  background-repeat: no-repeat;
}

.floatRight {
  right: 0 !important;
}

.topNoLogued {
  top: calc(8px + 2.6em) !important;
}

.topLogued {
  top: calc(8px + 5.2em) !important;
}

.fontSize2,
.fontSize2 button,
.fontSize2.generic-type-101 .component-class > * {
  font-size: 2em !important;
}

.matFormFieldWhite .mat-form-field-label,
.matFormFieldWhite {
  color: #fff !important;
}
.round-logo {
  width: 30px;
  height: 30px;
  border: 2px solid rgb(var(--primary-color));
  border-radius: 50%;
  object-fit: contain;
  background: #fff;
}

.matFormFieldWhite .mat-form-field-underline {
  background: #fff !important;
}

.matFormFieldWhite .form-control:disabled,
.matFormFieldWhite .form-control {
  background: inherit !important;
}

.generic-type-12 .mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding: 0 !important;
}

.generic-type-12
  .mat-form-field-appearance-outline
  .mat-form-field-subscript-wrapper {
  position: relative !important;
}

.cover-image-generic img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.slogan-movin-generic {
  color: #fff;
  text-align: center;
  padding: 1em 0;
  font-weight: 600;
  font-size: 1.8rem !important;
}

.uppercase {
  text-transform: uppercase !important;
}

simple-snack-bar.mat-simple-snackbar span {
  font-weight: 600;
  color: #fff;
}

simple-snack-bar.mat-simple-snackbar button span {
  color: #fff;
}

@media (max-width: 960px) {
  .float-top-right button {
    padding: 11px !important;
    border-radius: 15em !important;
    width: auto !important;
    line-height: 0 !important;
    letter-spacing: 0 !important;
    min-width: 0 !important;
    font-weight: bold;
  }

  .float-top-right {
    bottom: calc(20px + 4.8em) !important;
    top: inherit !important;
    left: 20px;
  }

  .mat-expansion-panel-header {
    padding: 0 12px !important;
  }

  .float-top-right button span span {
    display: none !important;
  }

  .float-top-right button i {
    font-size: 24px !important;
  }
}

.fullHeightSectionNoActiveSesion1 {
  padding: 10vh 18vw !important;
}

@media (max-width: 767px) {
  .class-custom-home-no-logued-what-4d {
    padding: 10vh 1vw !important;
  }

  .generic-type-117 .ui-button {
    display: inline-block !important;
  }

  .generic-type-117 p-carousel .ui-carousel-next .pi {
    border-left-color: #000;
  }

  .generic-type-117 p-carousel .ui-carousel-prev .pi {
    border-right-color: #000;
  }

  .super-tittle-web {
    font-size: 2.8em;
  }

  .fullHeightSectionNoActiveSesion1 {
    padding: 10vh 1vw !important;
  }

  .dialog-material-generic-close-out {
    /*height: 100% !important;*/
    width: 100% !important;
    max-height: 100% !important;
  }

  .dialog-material-generic-no-close-out {
    padding: 0 !important;
  }

  /*.dialog-material-generic-no-close-out mat-dialog-container {
    height: 100% !important;
    width: 100% !important;
  }*/

  .mat-expansion-panel-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mat-tab-label-container {
    padding: 0 !important;
  }

  .mat-tab-label {
    padding: 0 5px !important;
  }

  .generic-buttons-blue.no-float-top-right button,
  .generic-buttons-yellow.no-float-top-right button,
  .generic-buttons-red.no-float-top-right button,
  .generic-buttons-black.no-float-top-right button,
  .generic-buttons-blue-revert.no-float-top-right button,
  .generic-buttons-yellow-revert.no-float-top-right button,
  .generic-buttons-red-revert.no-float-top-right button,
  .generic-buttons-black-revert.no-float-top-right button {
    font-size: 12px !important;
    padding: 1px 10px !important;
  }

  .material-icons {
    font-size: 23px !important;
  }

  .generic-buttons-blue.no-float-top-right button .material-icons,
  .generic-buttons-yellow.no-float-top-right button .material-icons,
  .generic-buttons-red.no-float-top-right button .material-icons,
  .generic-buttons-black.no-float-top-right button .material-icons,
  .generic-buttons-blue-revert.no-float-top-right button .material-icons,
  .generic-buttons-yellow-revert.no-float-top-right button .material-icons,
  .generic-buttons-red-revert.no-float-top-right button .material-icons,
  .generic-buttons-black-revert.no-float-top-right button .material-icons,
  .no-float-top-right button .material-icons {
    font-size: 16px !important;
  }

  .float-top-right button i {
    font-size: 24px !important;
  }

  .mat-dialog-content {
    padding: 0 9px !important;
  }

  .mat-dialog-title, .dialog-material-generic .mat-dialog-actions {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .generic-type-7 .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    width: 100%;
  }

  .generic-type-7 mat-expansion-panel-header .mat-content {
    flex-wrap: wrap;
  }

  .generic-type-96 .mat-stepper-vertical-line::before {
    display: none;
  }

  .generic-type-96 .mat-vertical-content-container  {
    /* MIRAR PORQUE NO LO COJE */
    margin: 0 !important;
  }

  .dialog-material-generic .generic-type-96 .mat-vertical-content  {
    /* MIRAR PORQUE NO LO COJE */
    padding: 0 0px 24px 0px !important;
  }

  .dialog-material-generic .generic-type-96 mat-step-header {
    padding: 24px 0;
  }
}

.logo-report-static-html img {
  width: 6vw;
  min-width: 60px;
  height: 6vw;
  min-height: 60px;
  border-radius: 50%;
  border: 2.5px solid rgb(66, 103, 143);
  background: rgb(255, 255, 255);
  object-fit: contain;
}

.fix-class-radio .mat-radio-button label {
  justify-content: center;
}

.full-size-radio .mat-radio-button {
  width: 100% !important;
}

.full-size-radio .mat-radio-button label {
  justify-content: left !important;
}

.custom-edit-evento-agenda .mat-form-field-appearance-legacy .mat-form-field-prefix .mat-icon,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon {
  padding: 3px;
  width: calc(1em + 6px) !important;
  font-size: 17px;
}

.filter-table-generic-bottomsheet app-bottom-sheet-filter-table, .filter-table-generic-bottomsheet app-table-views {
  max-height: 100%;
  overflow: hidden;
}

.input_descuento label {
  color: #fff !important;
}

.input_descuento .mat-form-field-underline {
  background: #fff !important;
}

.fullWindowDialog {
  padding: 1vh 1vw !important;
}

.blackBackgroundDialog .mat-dialog-container {
  background: #383838 !important;
}

.own-required-invalid-autocomplete-field .mat-form-field-outline {
  opacity: 1 !important;
}

.own-required-invalid-autocomplete-field
  .mat-form-field-outline-thick
  .mat-form-field-outline-gap,
.own-required-invalid-autocomplete-field
  .mat-form-field-outline-thick
  .mat-form-field-outline-start,
.own-required-invalid-autocomplete-field
  .mat-form-field-outline-thick
  .mat-form-field-outline-end {
  border-color: red !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-outline-gap {
  border-top-color: transparent !important;
}

.canvasjs-chart-credit {
  display: none !important;
}

.generic-type-6 textarea {
  overflow: auto !important;
}

.dates-filter-tables-generic .mat-form-field-suffix {
  display: flex;
  align-items: baseline;
}

.cdk-overlay-pane:empty {
  display: none !important;
  background: inherit !important;
  background: inherit !important;
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  right: 100vw !important;
  top: 100vh !important;
}

.generic-type-12 .material-icons,
.generic-type-6 .material-icons,
.clear-stars-generic {
  vertical-align: inherit;
}

.generic-tab-full-width-2.tabs-agenda-compartida .mat-tab-header {
  width: calc(100% - 32px);
}

.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.2);
}

.noPaddings {
  padding: 0 !important;
}

.mat-select-disabled .mat-select-value , .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow , .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #000000bd !important;
}

.side-nav-general .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.agenda-selectors-background {
  background-color: #dbdbdb !important;
}

.agenda-selectors-background-allways {
  padding: 10px;
  display: block;
}

.agenda-selectors-background-allways:hover {
  background-color: rgba(var(--primary-color), 0.9)b8 !important;
  color: #fff !important;
  font-weight: 600 !important;
  transition: 0.5s;
}

.agenda-selectors-background-allways:hover .mat-checkbox-layout {
  border: 1px solid #fff;
  border-radius: 2px;
  margin-right: 5px;
  transition: 0.5s;
}

.scroll-lists-agenda {
  height: 50vh;
  overflow: hidden;
  overflow-y: auto;
}

.list-group-item {
  cursor: pointer;
  padding: 0 !important;
  margin: 0 !important;
}

.list-group-item:first-child {
  border-top: 0;
}

.list-group li:first-child , .list-group li:first-child span {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.list-group li:last-child , .list-group li:last-child span {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.all-activities-table-agenda tr:hover .material-icons {
  color: #fff !important;
}

.generic-taules .column-only-icon .mat-sort-header-content {
  width: 100%;
}

.generic-taules th button {
  line-height: inherit;
}
.addElementDialog .mat-dialog-container {
  min-width: 700px !important;
  max-width: 700px !important;
}

.example-list:hover .example-box .singleElement > .creating-mode-buttons {
  display: flex;
}

.previewMode > .containerList .showButton,
.previewMode > .containerList .edit,
.previewMode > .containerList .delete,
.previewMode > .containerList .firstAddButton,
.previewMode > .containerList .creating-mode-buttons,
.previewMode > .containerList .example-box:hover > .singleElement > .creating-mode-buttons {
  display: none !important;
}

.previewMode > .containerList .example-list,
.previewMode > .containerList .singleElement,
.previewMode > .containerList .example-box,
.previewMode > .containerList .w100,
.previewMode > .containerList .w50,
.previewMode > .containerList .w25,
.previewMode > .containerList .w33,
.previewMode > .containerList .w70,
.previewMode > .containerList .w30 {
  border: none !important;
}

.previewMode .generic-creator-add {
  display: none !important;
}

.cdk-global-overlay-wrapper:has(app-add-generic-element-dialog) {
  justify-content: right !important;
  align-items: end !important;
  padding-right: 10px !important;
  padding-bottom: 10px !important;
}

.mat-form-field-disabled .mat-form-field-flex , .generic-input-disabled .mat-form-field-flex {
  background: linear-gradient(to bottom, #fff 0%, #f5f5f5 15%, #f5f5f5 100%);
  /*opacity: 0.75;*/
}

.mat-expansion-panel {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mat-expansion-panel.mat-expanded {
  border-top-right-radius: 3px !important;
  border-top-left-radius: 3px !important;
  border-bottom-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.mat-dialog-container {
  border-radius: 7px !important;
}

.mat-accordion {
  margin-bottom: 4px;
}

.mat-tab-label {
  border-top-right-radius: 7px !important;
  border-top-left-radius: 7px !important;
}

.mat-tab-header {
  padding-top: 3px;
  border-bottom: 2px solid rgba(0,0,0,.05);
}

.mat-tab-group .mat-ink-bar {
  opacity: 0.5;
}

.mat-form-field-outline-start {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.mat-form-field-outline-end {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.matFormFieldWhite .mat-form-field-outline , .matFormFieldWhite.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick , .matFormFieldWhite label , .matFormFieldWhite.mat-form-field.mat-focused .mat-form-field-label {
  color: #fff !important;
}

.matFormFieldWhite.ng-invalid .mat-form-field-outline {
  color: #f44336 !important;
}

.matFormFieldWhite .mat-input-element , .matFormFieldWhite textarea  , .matFormFieldWhite input {
  caret-color: #fff !important;
}

.matFormFieldWhite.formFieldNoPadding.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0 !important;
}

.form-control:focus {
  color: inherit !important;
  background-color: inherit !important;
  border-color: inherit !important;
  outline: inherit !important;
  box-shadow: inherit !important;
}

.mat-snack-bar-container {
  white-space: pre-line;
}

.mat-input-element[type=date]::after, 
.mat-input-element[type=datetime]::after, 
.mat-input-element[type=datetime-local]::after, 
.mat-input-element[type=month]::after, 
.mat-input-element[type=week]::after, 
.mat-input-element[type=time]::after {
  display: none;
}

.slide-up-animation {
  position: fixed;
  bottom: -100vh;
  animation: slide-up 1s ease-out 3s forwards;
}

@keyframes slide-up {
  0% {
    bottom: -100vh;
  }
  100% {
    bottom: 0;
  }
}

app-dialog {
  position: relative;
}

/*
table .generic-type-5 {
  display: none;
}

table tr:hover .generic-type-5 {
  display: initial;
}
*/

.previewModeGenericSlider .mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: #fff !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected,.mat-calendar-body-comparison-identical) {
  color: #fff !important;
}

.generic-type-117.individual .ui-carousel-container .ui-carousel-prev {
  position: absolute !important;
  left: calc(1vw - 11px);
  z-index: 1;
  margin: 0 !important;
}

.generic-type-117.individual .ui-carousel-container .ui-carousel-next {
  position: absolute !important;
  right: 1vw;
  z-index: 1;
  margin: 0 !important;
}

.generic-type-117.individual .ui-carousel-container .ui-button .pi{
  border: solid black !important;
  border-width: 0 3px 3px 0 !important;
  display: inline-block !important;
  padding: 8px !important;
}

.generic-type-117.individual .ui-carousel-container .ui-carousel-next {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.generic-type-117.individual .ui-carousel-container .ui-carousel-prev {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.generic-type-117.individual .ui-state-disabled, .generic-type-117.individual .ui-widget:disabled {
  display: none;
}

.generic-type-117.individual .ui-carousel-dots-container {
  position: absolute;
  max-width: calc(100% - 1em);
  bottom: 20px;
  margin: 0 !important;
  width: 100%;
}

.generic-type-117.individual .ui-carousel-dot-item:only-child {
  display: none;
}

.generic-type-117.individual .ui-carousel-content {
  overflow: hidden !important;
}

.mat-form-field:not(.generic-input-disabled) .mat-hint:not(.mat-form-field-hint-end) {
  opacity: 0;
}

.mat-form-field.mat-focused .mat-hint:not(.mat-form-field-hint-end) {
  font-weight: bold;
  color:rgb( var(--tertiary-color));
  opacity: 0.8 !important;
  transition: 0.35s;
  transition-delay: 0.3s;
}

.body-in-iframe::-webkit-scrollbar {
  display: none !important;
}

.mat-calendar-body-disabled {
  cursor: not-allowed !important;
}

mat-calendar:has(.mat-calendar-body-disabled) .mat-calendar-body-cell:not(.mat-calendar-body-disabled ) div {
  font-weight: bold;
}

.bottomsheet-menu .material-icons {
    font-size: 16px !important;
}

.bottomsheet-menu .mat-list-base .mat-list-item .mat-list-item-content {
  color: #000 !important;
}

.bottomsheet-menu mat-expansion-panel-header mat-panel-title, .bottomsheet-menu .mat-expansion-panel-header-description, .bottomsheet-menu .mat-expansion-indicator::after, .bottomsheet-menu:has(.mat-accordion) .mat-list-base .mat-list-item .mat-list-item-content, .bottomsheet-menu .mat-list-base .mat-list-option .mat-list-item-content {
  color: #fff !important;
}

.bottomsheet-menu .mat-list-base .mat-list-item.highlight .mat-list-item-content {
  color: rgb(var(--secondary-color)) !important;
}

.bottomsheet-menu mat-expansion-panel:has(.highlight) mat-expansion-panel-header mat-panel-title, .bottomsheet-menu mat-expansion-panel:has(.highlight) .mat-expansion-panel-header-description, .bottomsheet-menu mat-expansion-panel:has(.highlight) .mat-expansion-indicator::after {
  color: rgb(var(--secondary-color)) !important;
}

.bottomsheet-menu .mat-expansion-panel-content {
  background: #00000000 !important;
}

.bottomsheet-menu mat-expansion-panel {
  background: rgb(var(--primary-color)) !important;
}

.bottomsheet-menu .mat-expansion-panel .mat-expansion-panel-header {
  background: #0000001f !important;
}

.mat-option:has(.mat-progress-spinner) {
  height: 40px !important;
}

.cdk-overlay-container {
  z-index: 999999999999;
}

app-dialog .stepper-header-hidden .mat-vertical-content-container {
  margin: 0 !important;
}

.fotoPerfilGeneric img {
  height: 30px !important;
  width: 30px !important;
  border-radius: 20% !important;
  border: 1px solid rgb(var(--primary-color)) !important;
  object-fit: cover !important;
}

#body:has(.infoFADevCloseIcon) .cdk-overlay-container {
  margin-left: calc(50vw - 5px);
  max-width: calc(50vw - 5px);
}


.icon-generic-highlight {
  color:rgb( var(--tertiary-color));
  border: 1px solidrgb( var(--tertiary-color));
  border-radius: 50px;
  background: #f4f4f4;
}

.allParagraphsWithSpace p {
  width: calc(100% - 20px) !important;
  margin: 20px 10px 0px 10px !important;
}

.big-size-form-field .mat-select-value-text {
  font-size: 20px !important;
}

.extrabig-size-form-field .mat-select-value-text {
  font-size: 24px !important;
}

.big-size-form-field label {
  font-size: 18px !important;
}

.extrabig-size-form-field label {
  font-size: 22px !important;
}

.mat-menu-content .highlight {
  background: rgb(var(--secondary-color)) !important;
  font-weight: bold !important;
  border-top: 2px solid #000 !important;
  border-bottom: 2px solid #000 !important;
}

.mat-menu-content .highlight i, .mat-menu-content .highlight span , .mat-menu-content .highlight div, .mat-menu-content .highlight, .mat-menu-content .highlight .material-icons {
  color: #000 !important;
}

.mat-menu-content .highlight:hover i , .mat-menu-content .highlight:hover span , .mat-menu-content .highlight:hover div, .mat-menu-content .highlight:hover, .mat-menu-content .highlight:hover .material-icons {
  color: #fff !important;
}

.mat-tab-body-wrapper:has(app-restaurant-pedidos-mesas-custom) {
  padding: 5px 0px !important;
}

.cdk-overlay-pane:has(.full-dialog) {
  padding: 8px !important;
}

.mat-dialog-container:has(.full-dialog) {
  height: 100% !important;
  border-radius: 5px !important;
  width: 100% !important;
}

.full-dialog .mat-dialog-content {
  max-height: 80vh !important;
}

.full-dialog .mat-dialog-container {
  width: 100% !important;
}

textarea.mat-input-element {
  padding: 0 !important;
}

.create-pdf-generic, .create-pdf-generic .printGenericSection {
  padding-left: 44px !important;
  padding-right: 44px !important;
  width: 980px !important;
  display: block !important;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  flex-basis: auto !important;
  max-width: 980px !important;
}

.create-pdf-generic span:has(.printGenericSection) {
  width: 980px !important;
  max-width: 980px !important;
}

.create-pdf-generic:has(.printGenericSection){
  padding: 0 !important;
  width: 100% !important;
  display: flex !important;
  flex-grow: 1 !important;
  flex-shrink: 1 !important;
  flex-basis: initial !important;
  max-width: initial !important;
 }
 
 .mat-bottom-sheet-container-large:has(#table-filters-generic), .mat-bottom-sheet-container-medium:has(#table-filters-generic) {
  max-width: 100vw !important;
 }

 .mat-bottom-sheet-container-large {
  min-width: 600px !important;
}

 .mat-expansion-panel-header {
  background-color: rgba(var(--secondary-color), 0.1) !important;
 }

 .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
   background-color: rgb(var(--secondary-color)) !important;
 }

 .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
   transform: translate3d(19px, 0, 0) !important;
 }

app-generic-custom-creator-options .mat-form-field .mat-hint {
  opacity: 1 !important;
}

.generic-buttons-blue .material-icons-two-tone {
  filter: invert(44%) sepia(5%) saturate(4638%) hue-rotate(171deg) brightness(82%) contrast(83%);
}

.generic-buttons-red .material-icons-two-tone {
  filter: invert(18%) sepia(24%) saturate(5345%) hue-rotate(326deg) brightness(90%) contrast(92%);
}

.generic-buttons-yellow .material-icons-two-tone {
  filter: invert(96%) sepia(12%) saturate(6559%) hue-rotate(357deg) brightness(97%) contrast(105%);
}

.generic-buttons-black .material-icons-two-tone {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(9deg) brightness(88%) contrast(102%);
}

.generic-buttons-yellow-revert .material-icons-two-tone {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(9deg) brightness(88%) contrast(102%);
}

.generic-buttons-blue-revert .material-icons-two-tone, .generic-buttons-red-revert .material-icons-two-tone, .material-icons-two-tone, .generic-buttons-black-revert .material-icons-two-tone {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.mat-menu-panel {
  min-height: 10px !important;
}

app-generic-container {
  width: 100%;
}


.bottomsheet-menu {
  background-image: linear-gradient(45deg, rgba(var(--tertiary-color), 0.65), rgba(var(--secondary-color), 0.8));
}

.bottomsheet-menu .mat-accordion {
  background-image: linear-gradient(45deg, rgba(var(--tertiary-color), 0.65), rgba(var(--secondary-color), 0.8));
}

.bottomsheet-menu:has(.mat-accordion) {
  background-image: inherit;
}

.mat-bottom-sheet-container:has(.bottomsheet-menu):has(.mat-accordion) {
  background: transparent !important;  
}

app-generic-container {
  max-width: 100%;
  background: #fcfcfc;
  height: 100%;
  overflow: auto;
  position: relative;
}

.window-splitted {
  app-generic-container {
    max-width: calc(50% - 4px);
    border-radius: 6px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
  app-generic-container:first-child {
    margin-right: 8px;
  }
  app-generic-container:last-child {
    margin-left: 8px;
  }
}

.window-splitted.container-logged {
  box-shadow: inherit !important;
}

.mat-menu-item {
  width: 100% !important;
}

.mat-bottom-sheet-container:has(#table-filters-generic) {
  padding: 0 !important;
}

.create-pdf-generic .hidePrintGenericSection {
  display: none !important;
}

@media (min-width: 960px) {
  .hideSideMenu .specials-icon {
      left: calc(100% - 35px) !important;
  }
}

.big-form-fields .mat-form-field-disabled input.mat-input-element, .big-form-fields .mat-select-value-text, .big-form-fields .mat-select-panel .mat-optgroup-label, .big-form-fields .mat-select-panel .mat-option {
  font-size: 18px !important;
}

.big-form-fields {
  font-size: 20px !important;
}

.big-form-fields mat-checkbox .mat-checkbox-label {
  font-size: 13px !important;
}

#body:has(.side-history-generic-component) .float-top-right {
  right: calc(20% + 0.6vw) !important;
  transition: 0.5s;
}

#body:has(.side-history-generic-component) .fc-widget-small {
  display: none !important;
}

body:has(.dev-check-static.mat-checkbox-checked) .parentElement, body:has(.generic-form-actions .mat-checkbox-checked) .parentElement {
  padding: 6px;
}

body:has(.dev-check-printer-view.mat-checkbox-checked) .printGenericSection {
  padding: 7px !important;

  app-generic {
    background-color: grey !important;
    border-radius: 3px !important;
    padding: 7px;

    app-generic {
      background-color: initial !important;
      border-radius: initial !important;
    }
  }
}

app-generic-creator-custom .parentElement {
  padding: 0 !important;
}

.generic-type-3:has(.bottom-table-generic-mb) .single-table:has(.indexTable-0) mat-paginator {
  position: relative !important;
  top: inherit;
  right: inherit;
  width: 100% !important;
}

.generic-type-3:has(.bottom-table-generic-mb) .mat-paginator-range-label {
  margin: 0 10px 0 6px !important;
}

.mat-button-toggle {
  padding: 0 1.5% !important;
}

#body:has(.window-info) .cdk-overlay-container:has(.mat-dialog-container) {
  display: none !important;
}

app-menu-generic-element .debbugingIdsIcon {
  display: none !important;
}

app-menu-generic-element:hover .debbugingIdsIcon {
  display: initial !important;
}


.denied-generic {
  color: red;
}

.checked-generic {
  color: green;
  font-weight: bold;
}

.checked-generic , .denied-generic {
  border-radius: 50%;
  background: #ffffffd6;
  transition: all 0.08s ease-in-out;
  margin-left: calc(25% - 10px);
}
@use '@angular/material' as mat;
@import '@angular/material/theming';
@import './theme';

:root {
  --primary-color: 90, 138, 190;
  --secondary-color: 253, 221, 0;
  --tertiary-color: 136, 35, 52;
  --quaternary-color: 90, 138, 190;
  @include theme(rgb(var(--primary-color)), rgb(var(--secondary-color)), rgb(var(--tertiary-color)), rgb(var(--quaternary-color)));
}

$my-primary: (
  50: rgb(var(--primary-color)),
  100: rgb(var(--primary-color)),
  200: rgb(var(--primary-color)),
  300: rgb(var(--primary-color)),
  400: rgb(var(--primary-color)),
  500: rgb(var(--primary-color)),
  600: rgb(var(--primary-color)),
  700: rgb(var(--primary-color)),
  800: rgb(var(--primary-color)),
  900: rgb(var(--primary-color)),
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$my-secondary: (
  50: rgb(var(--secondary-color)),
  100: rgb(var(--secondary-color)),
  200: rgb(var(--secondary-color)),
  300: rgb(var(--secondary-color)),
  400: rgb(var(--secondary-color)),
  500: rgb(var(--secondary-color)),
  600: rgb(var(--secondary-color)),
  700: rgb(var(--secondary-color)),
  800: rgb(var(--secondary-color)),
  900: rgb(var(--secondary-color)),
  contrast: (50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

@include mat.core();
$app-primary: mat.define-palette($my-primary, 50);
$app-accent: mat.define-palette($my-primary, 50);
$app-warn: mat.define-palette(mat.$red-palette);
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);
@include mat.all-component-themes($app-theme);
